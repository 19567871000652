export interface FiltersModel {
  id?: number | string;
  name?: string;
  external_id?: string;
  page?: number;
  page_size?: number;
  created_min?: string;
  created_max?: string;
  modified_min?: string;
  modified_max?: string;
  created_by_id?: number;
  modified_by_id?: number;
  customer_id?: number | null;
  search?: string;
  ordering?: string;
  status?: string;
  created?: any;
  modified?: any;
  action?: string;
  type__id?: string;
  type__name?: string;
  customer_group_id?: number;
  type_id?: number;
  revision_id?: number | string;
  location_source__names?: string;
  names__location?: string;
  location_destination__names?: string;
  event_tasks__name?: string;
  event_tasks__id?: string | number;
  sku__model?: string;
  entry_location_id?: any;
  last_evaluated_key_id?: string;
  first?: number;
}

export interface aditionalHeadersModel {
  'x-api-key'?: string;
  accept?: string;
}
