/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @nx/enforce-module-boundaries */
import {
  booleanAttribute,
  Component,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { AlertService } from '@kanzi-apes/kanzi-alerts';
import { CustomerModel } from '@kanzi-apes/kanzi-models';
import { AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { PropertiesModels } from './models';
import { TablePropertiesService } from './table-properties.service';

export type TypeCreateProps = 'PRODUCTS' | 'PRODUCTION_ORDERS';

@Component({
  selector: 'table-properties',
  exportAs: 'tableProperties',
  templateUrl: './kanzi-table-properties.component.html',
  styleUrls: ['./kanzi-table-properties.component.scss'],
})
export class KanziTablePropertiesComponent implements OnInit {
  public filteredKeyParams: any[] = [];
  public keyParamsOptions: any[] = [];
  public newKeyParamName = '';
  public requiredProp = false;
  public customParamsList: { key: string; value: string }[] = [
    { key: '', value: '' },
  ];
  public optionsValue = signal<{ key: string; value: string }[]>([]);

  @Input()
  public typeTableProps: 'REQUIRED' | 'OPTIONAL' | 'DEFAULT' = 'DEFAULT';

  @Input({ transform: booleanAttribute })
  public deleteAll = false;

  @Input({ transform: booleanAttribute })
  public loadPropsValue = false;

  @Input()
  public customer: CustomerModel | null = null;

  @Input()
  public type: TypeCreateProps = 'PRODUCTS';

  @Input()
  public set propertiesEdit(data: { [key: string]: any } | null) {
    if (!data) return;
    this.customParamsList = [];

    const keys = Object.keys(data).filter((key) => key !== '');

    keys.forEach((key) => {
      this.customParamsList.push({
        key,
        value: data[key],
      });
    });
  }

  get propList(){
    return this.customParamsList.filter(p=>p.key!=='')
  }

  get isValidList() {
    return this.customParamsList?.every((p) => p.value !== '');
  }

  constructor(
    private alertService: AlertService,
    private readonly propertiesService: TablePropertiesService
  ) {}

  ngOnInit(): void {
    this.getProperties(null);
  }

  getProperties(filters: PropertiesModels.Filters | null) {
    this.propertiesService.getProperties(filters).subscribe({
      next: (payload) => {
        if (this.typeTableProps === 'DEFAULT') {
          this.filteredKeyParams = payload.results.concat([]);
        }

        if (this.typeTableProps === 'OPTIONAL') {
          this.filteredKeyParams = payload.results
            .filter((p) => !p.required)
            .concat([]);
        }

        if (this.typeTableProps === 'REQUIRED') {
          this.requiredProp = true;
          this.filterByRequired(payload);
        }
      },
    });
  }

  filterByRequired(data: PropertiesModels.List) {
    const propsRequired = data.results.filter((prop) => prop.required);
    this.customParamsList = propsRequired.map((prop) => ({
      key: prop.name,
      value: '',
    }));
  }

  addKeyParamText() {
    this.propertiesService
      .createProperties({
        name: this.newKeyParamName,
        display_name: this.newKeyParamName,
        type: this.type,
        customer_id: this.customer?.id as number,
        required: this.requiredProp,
      })
      .subscribe({
        complete: () => {
          this.getProperties(null);
          this.newKeyParamName = '';
        },
      });
  }

  addToFormConfig(event: any, item: any) {
    // if (this.customParamsList[0].value) {
    //   if (item.key.match(/\s/g) || item.key.trim() === '') {
    //     this.alertService.error('No se permite nombres vacíos o con espacios');
    //   } else {
    //     this.deviceForm.patchValue({
    //       custom_params: this.customParamsList.reduce(
    //         (accumulator, value, index) => {
    //           return { ...accumulator, [value.key]: value.value };
    //         },
    //         {}
    //       ),
    //     });
    //   }
    // }
  }

  checkCustomKeyParamText(event: any) {
    // if (this.newKeyParamName) {
    //   if (
    //     this.newKeyParamName.match(/\s/g) ||
    //     this.newKeyParamName.trim() === ''
    //   ) {
    //     this.alertService.error('No se permite nombres vacíos o con espacios');
    //   }
    // }
  }

  filterKeyParam(event: any) {
    let query = event.query;
    this.getProperties({
      search: query,
    });
  }

  selectKeyParam(param: AutoCompleteSelectEvent, index: number) {
    if (param && param.value) {
      this.customParamsList[index].key = param.value.name;
      if (this.loadPropsValue) {
        this.propertiesService.getValueProps(param.value.name).subscribe({
          next: (payload) => {
            this.optionsValue.set(payload.results.filter((i) => i.value));
          },
        });
      }
    }
  }

  addRowProperty(): void {
    this.customParamsList.push({ key: '', value: '' });
  }

  deleteRowProperty() {
    if (this.customParamsList.length > 1 || this.deleteAll) {
      this.customParamsList.pop();
    }
  }

  setValueProp(
    event: { key: string; value: string },
    rowData: { key: string; value: string }
  ) {
    rowData.value = event.value;
  }
}
