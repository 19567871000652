import { ContactModel } from '../../contacts';
import { DataListModel, DataModel } from '../../data';
import { FiltersModel } from '../../filters';
import { SkuModel } from '../../products';
import { MaintenanceWorkOrderModel } from '../work-orders';

export interface MaintenanceBudgetCreateModel {
  revision_id?: number;
  source_id?: number;
  lines: MaintenanceBugetLineCreateModel[];
  requester_id: number;
  params: {
    costs: {
      total_price: number;
      subtotal_price?: number;
      iva_price?: number;
      total_price_iva?: number;
    };
    discount_rate_list?: {
      discount_name: string;
      discount_rate: number;
      discount_value: number;
    }[];
    store_order_id?: number;
    aditional_services?: {
      external_id: string;
      description: string;
      total_price: number;
      price_disc: number;
      disc: number;
      amount: number;
      price: number;
    }[];
    requester_info?: ContactModel;
    sku: {
      model: string;
      serie: string;
      plate: string;
      horometroDiesel: string;
      horometroElectrico: string;
      odometerMeasure: string;
    };
  };
  cost: number;
  service: number[];
}

export interface MaintenanceBugetLineCreateModel {
  sku_id: number;
  amount?: number;
  quantity?: number;
  type_part: string;
  params: {
    costs: {
      unit_price: number;
      unit_price_disc?: number;
      percent_disc?: number;
    };
    sku_display_name?: string;
    sku_external_id?: string;
    iva:number;
  };
}

export interface BudgetEditModel {
  id?: number;
  revision_id?: number;
  source_id?: number;
  lines?: string[];
  external_id?: string;
  status?: string;
  attachments?: string;
  description?: string;
  params?: any;
  observations?: {
    process_budget: {
      reason: string;
      budget_status: 'REJECTED';
    };
  };
}

export interface MaintenanceBudgetOrderModel extends DataModel {
  id: number;
  revision: MaintenanceWorkOrderModel;
  status: string;
  params: {
    costs?: {
      total_price?: number;
      subtotal_price?: number;
      iva_price?: number;
      total_price_iva?: number;
    };
    discount_rate_list: {
      discount_name: string;
      discount_rate: string;
      discount_value: number;
    }[];
    store_order_id?: number;
    aditional_services?: {
      description: string;
      price_disc: number;
      disc: number;
      external_id: string;
      total_price: number;
      amount: number;
      price: number;
    }[];
    requester_info?: ContactModel;
    sku: {
      model: string;
      serie: string;
      plate: string;
      horometroDiesel: string;
      horometroElectrico: string;
    };
  };
  created_by_name?: string;
  params_total_price?: number;
  source?: any;
  attachments?: File;
  description?: string;
}

export interface MaintenanceBudgetOrderListModel extends DataListModel {
  results: MaintenanceBudgetOrderModel[];
}

export interface MaintenanceBudgetOrderFiltersModel extends FiltersModel {
  revision_id?: number;
  status?: string;
  budget_by_store_order_id?: number;
  store_order_id?: number;
}

export interface MaintenanceBudgetOrderLineFiltersModel extends FiltersModel {
  budget_id?: number;
}

export interface MaintenanceBudgetOrderLineListModel extends DataListModel {
  results: MaintenanceBudgetOrderLineModel[];
}

export interface MaintenanceBudgetOrderLineModel extends DataModel {
  id: number;
  budget: MaintenanceBudgetOrderModel;
  sku: SkuModel;
  sku_display_name?: string;
  sku_external_id?: string;
  status: string;
  amount?: number;
  quantity?: number;
  params?: {
    costs?: {
      unit_price?: number;
      unit_price_disc?: number;
      percent_disc?: number;
    };
    iva:number
  };
  params_unit_price?: number;
  created_by_name?: string;
}

/** Budget pdf generate models */

export interface BudgetPDFCreateModel {
  templateURI: string;
  dataToParse: DataToParseModel;
}

export interface DataToParseModel {
  id: string;
  nit: string;
  created: string;
  comments: string;
  total_sku_without_iva:string;
  source_name: string;
  showDiscount: boolean;
  revision: { external_id: string };
  sku: {
    model: string;
    serie: string;
    plate: string;
    horometroDiesel: string;
    horometroElectrico: string;
  };
  costs?: {
    total_price: string;
    subtotal_price?: string;
    iva_price?: string;
    total_price_iva?: string;
  };
  customer: {
    discount_rate_list?: IDiscounts[];
    name: string;
    identification: string;
    properties: {
      contact: string;
      email: string;
      city: string;
      labour: number;
    };
  };
  parts: Part[];
  tasks: TaskPDF[];
}

export interface IDiscounts {
  discount_name: string;
  discount_rate: string;
  discount_value: string;
}

export interface TaskPDF {
  name: string;
  external_id: string;
  estimated_time: string;
  group_name: string;
  parent_name: string;
}

export interface Part {
  sku: {
    display_name: string;
    external_id: string;
    properties?: {
      price: string;
      price_disc: string;
      disc_percent: string;
      total_price: string;
      total_price_disc: string;
      type_part: string;
      unit: string;
    };
  };
  amount?: number;
  quantity?: number;
}

export interface PriceSkuFilterModel extends FiltersModel {
  sku_id: number;
}

export interface IvaPriceSkuFilterModel extends FiltersModel {
  sku__id: number;
}

export interface IvaPriceSkuList extends DataListModel {
  results: IvaPriceSku[];
}

export interface IvaPriceSku {
  id: number;
  created_by: {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
  };
  modified_by: {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
  };
  customer: {
    id: number;
    name: string;
    type_customer: string;
    identification: null;
    parent: number;
  };
  service: any[];
  created: string;
  modified: string;
  is_active: boolean;
  name: string;
  external_id: null;
  description: string;
  type: string;
  increase_rate: number;
  params: any;
  sku: number[];
  created_timestamp: number;
  modified_timestamp: number;
}

export interface ServicePirceSkuListModel extends DataModel {
  results: ServicePirceSkuModel[];
}

export interface ServicePirceSkuModel {
  id: number;
  created_by: {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
  };
  modified_by: {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
  };
  created: string;
  modified: string;
  price: number;
  external_id: string;
  sku: PirceSkuInfoModel;
  customer: number;
  created_timestamp: number;
  modified_timestamp: number;
}

export interface PirceSkuInfoModel {
  id: number;
  external_id: string;
  name: string;
  display_name: string;
  properties: PropertiesSkuInfo;
}

export interface PropertiesSkuInfo {
  Tipo: string;
  Width: string;
  hight: string;
  large: string;
  'Equipo/Pieza': string;
  Precio_Unitario: string;
  'CATEGORIA/FAMILIA': null;
}
