/* eslint-disable @nx/enforce-module-boundaries */
import { inject, Injectable } from '@angular/core';
import {
  ProductsWMSFilterModel,
  ProductWMSListModel
} from '@kanzi-apes/kanzi-models';
import { KanziRestClientService } from '@kanzi-apes/kanzi-rest-api';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductLineWmsService {
  private httpClient = inject(KanziRestClientService);
  private envService = inject(EnvironmentService);

  private productsWMSUrl = `${this.envService.wmsEnvironment?.apiRestModules.kongWMSAPI?.products.apiURL}`;
  private productsWMSPath = `${this.envService.wmsEnvironment?.apiRestModules.kongWMSAPI?.products.apiPath}`;


  /**
   * Method to get the list of products.
   * @param filters {ProductsWMSFilterModel | null} Filters to apply to the list.
   * @returns Observable {ProductWMSListModel}
   */
  getProductList(
    filters: ProductsWMSFilterModel | null
  ): Observable<ProductWMSListModel> {
    const url = `${this.productsWMSUrl}variant/`;
    return this.httpClient.get(
      url,
      filters,
      'Error al listar los productos',
      {},
      { tokenName: 'KanziWMSUserToken', tokenPrefix: 'COGNITO' }
    );
  }
}
