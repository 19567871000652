import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Router, UrlTree } from '@angular/router';
import { from, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { exhaustMap, map, tap } from 'rxjs/operators';
import * as Auth from '../../+state/auth.actions';
import * as fromAuth from '../../+state/auth.reducer';
import { AuthService } from '../auth/auth.service';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

const checkAuthStatus = ():
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const authService = inject(AuthService);
  const store = inject(Store);
  return from(authService.currentAuthenticatedUserObservable()).pipe(
    map((currentUser) =>
      authService.getSessionValidUserObservable(currentUser)
    ),
    map((sessionValid) =>
      sessionValid.pipe(
        map((session) => {
          console.log('session status', session);
          if (session) {
            return true;
          } else {
            store.dispatch(Auth.loginRedirect());
            return false;
          }
        })
      )
    ),
    exhaustMap((sessionValid) => sessionValid)
  );
  let currentAuthenticatedUser = authService.currentAuthenticatedUser();
  currentAuthenticatedUser?.getSession(
    (err: any, session: CognitoUserSession) => {
      if (err) {
        store.dispatch(Auth.loginRedirect());
        return false;
      }
      console.log('session status', session.isValid());
      if (session.isValid()) {
        return true;
      } else {
        store.dispatch(Auth.loginRedirect());
        return false;
      }
    }
  );
  /* store.dispatch(Auth.loginRedirect());
  return false; */
};

export const canActivateFn: CanActivateFn = () => {
  return checkAuthStatus();
};

export const canMatchFn: CanMatchFn = () => {
  return checkAuthStatus();
};
