import {
  CognitoUserAttribute,
  CognitoUserSession,
  UserData,
} from 'amazon-cognito-identity-js';

export interface LoginFormModel {
  username: string;
  password: string;
}

export interface ResetPasswordFormModel {
  username: string;
}

export interface ForgotPasswordFormModel {
  code: string;
  new_password: string;
}

export interface ConfirmCodeFormModel {
  username: string;
  code: string;
}

export interface TokenModel {
  auth_token: string;
}

export interface ConfirmSignUpModel {
  username: string;
  code: string;
}

export interface UserSignUpParamsModel {
  username: string;
  password: string;
  attributes?: object;
  clientMetadata?: {
    [key: string]: string;
  };
}

export interface SignUpResultModel {
  user: any;
  userConfirmed: boolean;
  userSub: string;
  codeDeliveryDetails: any;
}

export interface UpdatePasswordModel {
  username?: string;
  code?: string;
  new_password: string;
  old_password?: string;
}

export interface VerifyCodeModel {
  username?: string;
  code?: string;
}

export interface NewPasswordFormModel {
  newPassword: string;
  confirmPassword: string;
}

export interface CompleteNewPasswordModel {
  newPassword: string;
}

export interface UserCognitoModel extends UserData {}

export interface UsersModel {}

export interface LoginCognitoSuccess {
  userSession: CognitoUserSession;
  userConfirmation: boolean | undefined;
}

export interface LoginChangePasswordRequired {
  challengeName: 'NEW_PASSWORD_REQUIRED';
  userAttibutes: any;
  requiredAttributes: any;
}

export interface UserConfigsWMSFilterModel {
  username__eq: string;
  merge_rol_actions: boolean;
}
