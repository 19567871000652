import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { LocationListService } from '@kanzi-apes/kanzi-locations';
import {
  CustomerModel,
  KanziStatus,
  LocationListModel,
  LocationModel,
  SkuListModel,
  SkuModel,
  UserModel,
  UserRoles,
} from '@kanzi-apes/kanzi-models';
import { MessageService } from '@kanzi-apes/kanzi-prime-ui';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';
import { forkJoin } from 'rxjs';
import { ProductLineService } from './product-list.service';


/* TODO:Refactor */
@Component({
  selector: 'kanzi-dynamic-sku-line-create',
  templateUrl: './kanzi-dynamic-sku-line-create.component.html',
  styleUrls: ['./kanzi-dynamic-sku-line-create.component.scss'],
})
export class KanziDynamicSkuLineCreateComponent implements OnInit, OnChanges {
  @Input() set linesList(data: any[]) {
    if (data.length > 0) {
      this._linesList = data.map((o) => ({ ...o }));
      this.skuLinesSelected.emit(this._linesList);
      this.validForm.emit(true);
    }
  }
  @Input() set resetLines(isReset: boolean | null) {
    if (isReset) {
      this._linesList = [];
    }
  }
  @Input() positioning: boolean;
  @Input() locationParentId: number;
  @Input() user: UserModel | null;
  @Input() customer: CustomerModel | null;
  @Input() unitPrice = false;
  @Input() byExternalId = false;
  @Input() canAddLines = true;
  @Input() loading = false;
  @Output() skuLinesSelected = new EventEmitter<any[]>();
  @Output() validForm = new EventEmitter<boolean>();
  @Output() selectAllLines = new EventEmitter<number>();
  @Output() modalSkus = new EventEmitter<boolean>();
  @Output() onDeleteLine = new EventEmitter<any>();

  linesSelected: any[] = [];
  _validAmount = false;
  _activeButtons = false;
  _linesList: any[] = [];
  displayDialog  = false;
  skuListText = '';
  skusList: SkuModel[] = [];
  skusListFiltered: SkuModel[] = [];
  cloneLines: SkuModel[] = [];
  locationsSuggested: LocationModel[] = [];
  locationsList: LocationListModel | null = null;
  displayDialogScan: boolean = false;
  displaySider: boolean = false;
  defaultImage: string = '';
  selectedSku: SkuModel[] = [];
  selectedSkuEdit: SkuModel | null = null;
  pendingList = signal<boolean>(false);
  showSelectTypeList: boolean = false;
  isQuantity = false;

  set filterSkusList(query: string) {
    this.filterSkus(query);
  }

  constructor(
    private productListService: ProductLineService,
    private messageService: MessageService,
    private locationService: LocationListService,
    private envService: EnvironmentService
  ) {
    this.isQuantity = this.envService.environment?.isQuantity ?? false;
    this.showSelectTypeList = this.envService.environment?.modules
      ?.isEnableButtonExternalId
      ? this.envService.environment?.modules?.isEnableButtonExternalId
      : false;
    this.positioning = false;
    this.locationParentId = 0;
    this.user = null;
    this.customer = null;
    if (this.envService.environment) {
      this.defaultImage =
        this.envService.environment.assets.root +
        this.envService.environment.assets.paths.backgrounds +
        'not-image-400x300.png';
    }
  }

  ngOnInit() {}

  ngOnChanges() {
    if (
      this.locationParentId &&
      this.user &&
      this.user.configs &&
      this.user.configs.role &&
      this.user.configs.role.name === UserRoles.MANAGER
    ) {
      this.locationService
        .getlocationList({
          page: 1,
          page_size: 1000,
          parent_id: this.locationParentId,
        })
        .subscribe((locations: LocationListModel) => {
          this.locationsList = locations;
        });
    }
  }

  addSkuList() {
    this.displaySider = true;
    this.filterSkus('');
  }

  selectItem(sku: SkuModel) {
    this.selectedSku = sku.selected
      ? (this.selectedSku?.concat(sku) as SkuModel[])
      : this.selectedSku.filter((i) => i.id !== sku.id);
  }

  filterSkus(query: string) {
    this.pendingList.set(true);
    this.productListService
      .getSkusList({
        page: 1,
        search: query,
        page_size: 20,
        customer_id: this.customer ? this.customer.id : 2,
      })
      .subscribe({
        next: (skus: SkuListModel) => {
          this.skusList = skus.results.map((item) => ({
            ...item,
            amount: item.amount ? item.amount : 1,
            selected: item.selected ? item.selected : false,
            display_name: item.display_name,
            sku_id: item.id,
            sku_display_name: item.display_name,
            sku_external_id: item.external_id,
          }));
          if (this.selectedSku.length > 0) {
            const skuIds = this.selectedSku.map((i) => i.id);
            this.skusList = this.skusList.map((item) => {
              if (skuIds.includes(item.id)) {
                const skuSelected = this.selectedSku.find(
                  (s) => s.id === item.id
                );
                return {
                  ...item,
                  amount: skuSelected?.amount,
                  selected: skuSelected?.selected,
                };
              } else {
                return {
                  ...item,
                };
              }
            });
          }
        },
        complete: () => {
          this.pendingList.set(false);
        },
      });
  }

  // swichInputMode(event: { checked: boolean; originalEvent: PointerEvent }) {
  //   this.byExternalId = event.checked;
  // }

  onRowDelete(index: number) {
    if (this._linesList.length > 1) {
      this.onDeleteLine.emit(this._linesList[index]);
      this._linesList.splice(index, 1);
    } else {
      this.messageService.add({
        severity: KanziStatus.ERROR,
        summary: 'La orden debe tener al menos una linea.',
      });
      this._linesList = [];
      this.validForm.emit(false);
    }
    this.skuLinesSelected.emit(this._linesList);
  }

  onSelectSkuLocation(event: any, i: number) {
    const lc: LocationModel = event.value;
    if (lc) {
      this._linesList[i].sub_location_id = lc.id;
    } else {
      this._linesList[i].sub_location_id = 0;
      this._linesList[i].locationOption = null;
    }
  }

  // onInputSku(skuListText: string) {
  //   const skusList = this.getSkuId(skuListText);
  //   const { sku_id: currentSku } = skusList.pop();
  //   const findSku = skusList.find((item)=>item.sku_id===currentSku);

  // }

  getSkuId(skuListText: string) {
    const rowsSkus = this.skuListText.split('\n');
    let skusList: any[] = [];
    rowsSkus.forEach((row) => {
      const rowSku = row.split(',');
      const skuNum = rowSku[0].replace(/\s/g, '');
      let orderLine: any = {
        sku_id: skuNum,
        amount: this.isQuantity ? parseFloat(rowSku[1]) : parseInt(rowSku[1]),
      };
      skusList.push(orderLine);
    });

    return skusList.filter((item) => item.sku_id !== '');
  }

  saveListSkus() {
    if (this.skuListText) {
      const rowsSkus = this.skuListText.split('\n');
      if (rowsSkus.length > 0) {
        let skusList: any[] = [];
        rowsSkus.forEach((row) => {
          const rowSku = row.split(',');
          const skuNum = rowSku[0].replace(/\s/g, '');
          let orderLine: any = {
            sku: null,
            sku_display_name: '',
            sku_external_id: this.byExternalId ? skuNum : '',
            sku_id: skuNum,
            amount: this.isQuantity
              ? parseFloat(rowSku[1])
              : parseInt(rowSku[1]),
          };

          skusList.push(orderLine);
        });

        let skuListNoRepeat: any[] = [];
        const skus = Array.from(new Set(skusList.map((item) => item.sku_id)));

        skus.forEach((sku) => {
          const infoSku = skusList.find((i) => i.sku_id === sku);
          const totalAmount = skusList
            .filter((i) => i.sku_id === sku)
            .map((i) => i.amount)
            .reduce((c, t) => c + t);
          skuListNoRepeat.push({
            ...infoSku,
            amount: totalAmount,
          });
        });

        skusList = [...skuListNoRepeat];
        if (this.byExternalId) {
          const skuList = skusList.map((i) => {
            return this.productListService.getSkusList({
              page: 1,
              page_size: 1,
              external_id: i.sku_external_id,
              customer_id: this.customer ? this.customer.id : 0,
            });
          });

          forkJoin(skuList).subscribe((skus) => {
            const skuComplete = skus
              .filter((i) => i.results.length)
              .map((item) => {
                const sku = skusList.find(
                  (i) => i.sku_external_id == item.results[0].external_id
                );
                return {
                  ...item.results[0],
                  amount: sku ? sku.amount : 0,
                  selected: true,
                  display_name: item.results[0].display_name,
                  sku_id: item.results[0].id,
                  sku_display_name: item.results[0].display_name,
                  sku_external_id: item.results[0].external_id,
                };
              });
            this.groupSkuLines(skuComplete);
            this.delete();
          });
        } else {
          const skuList$ = skusList.map((i) =>
            this.productListService.getSkusId(i.sku_id)
          );
          forkJoin(skuList$).subscribe((skus) => {
            const skuComplete = skus.map((item) => {
              const sku = skusList.find((i) => i.sku_id == item.id);
              return {
                ...item,
                amount: sku ? sku.amount : 0,
                selected: true,
                display_name: item.display_name,
                sku_id: item.id,
                sku_display_name: item.display_name,
                sku_external_id: item.external_id,
              };
            });
            this.groupSkuLines(skuComplete);
            this.delete();
          });
        }
      }
    }
  }

  delete() {
    this.displayDialog = false;
    this.skuListText = '';
  }

  groupSkuLinesOld(lines: any[]) {
    const linesFiltered: any[] = [];
    lines.forEach((line) => {
      const lineFound = linesFiltered.find(
        (lineFiltered) => lineFiltered.sku_external_id === line.sku_external_id
      );
      if (!lineFound) {
        linesFiltered.push(line);
      } else {
        lineFound.amount += line.amount;
      }
    });

    this._linesList = [];
    this._linesList = this._linesList.concat(linesFiltered);
    this.skuLinesSelected.emit(this._linesList);
    this.validForm.emit(true);
  }

  groupSkuLines(skuList: any[]) {
    if (!skuList.length) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'No se encontró resultados asociados a la lista ingresada',
      });
    }
    if (this._linesList.length > 0) {
      skuList.forEach((sku) => {
        const skuFound = this._linesList.find((item) => item.id == sku.id);
        if (skuFound) {
          this._linesList = this._linesList.map((item) => {
            if (item.id == sku.id) {
              return {
                ...item,
                amount: sku.amount + item.amount,
              };
            }
            return {
              ...item,
            };
          });
        } else {
          this._linesList = this._linesList.concat(sku);
        }
      });
    } else {
      this._linesList = this._linesList.concat(skuList);
    }
    this.skuLinesSelected.emit(this._linesList);
    this.validForm.emit(true);
  }

  closeDialog() {
    this.displaySider = false;
    this.selectedSku = [];
    this.skusList = [];
  }

  showDialogToAdd() {
    this.displayDialog = true;
  }

  showDialogToScanCode() {
    this.displayDialogScan = true;
  }

  onSaveSelectSku() {
    this.groupSkuLines(this.selectedSku);
    this.selectedSku = [];
    this.skusList = [];
    this.displaySider = false;
  }

  onRowEditInit(skuLine: any) {
    this.selectedSkuEdit = { ...skuLine };
    this.validForm.emit(false);
  }

  onRowEditSave(skuLine: any) {
    if (skuLine.amount > 0) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Linea Actualizada.',
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'La cantidad debe ser mayor a 0.',
      });
    }
    this.skuLinesSelected.emit(this._linesList);
    this.validForm.emit(true);
  }

  onRowEditCancel(skuLine: any, index: number) {
    this._linesList[index] = this.selectedSkuEdit;
  }

  onSkuBarcodeReading(code: string) {
    const barcode = code.trim();
    let skuSelected: SkuModel;
    if (barcode) {
      this.productListService
        .getSkusList({ page: 1, search: barcode, page_size: 1 })
        .subscribe((skus: SkuListModel) => {
          if (skus.count > 0) {
            skuSelected = skus.results[0];
            this._linesList[this._linesList.length - 1].sku_id = skuSelected.id;
            this._linesList[this._linesList.length - 1].sku_display_name =
              skuSelected.display_name;
            this._linesList[this._linesList.length - 1].sku_external_id =
              skuSelected.external_id;
            this._linesList[this._linesList.length - 1].amount = 1;
            this._linesList[this._linesList.length - 1].sku = skuSelected;
            if (this.positioning && skuSelected.id) {
              this.productListService
                .getSkuLocationSuggested(skuSelected.id, this.locationParentId)
                .subscribe((data: any) => {
                  if (
                    this.user &&
                    this.user.configs?.role.name === UserRoles.MANAGER
                  ) {
                    data.locations = data.locations.concat(
                      this.locationsList?.results
                    );
                  }
                  this._linesList[
                    this._linesList.length - 1
                  ].locationsSuggested = data.locations;
                  if (
                    this._linesList[this._linesList.length - 1]
                      .locationsSuggested.length === 1
                  ) {
                    this._linesList[this._linesList.length - 1].locationOption =
                      this._linesList[
                        this._linesList.length - 1
                      ].locationsSuggested[0];
                    this._linesList[
                      this._linesList.length - 1
                    ].sub_location_id =
                      this._linesList[
                        this._linesList.length - 1
                      ].locationsSuggested[0].id;
                  } else if (
                    this._linesList[this._linesList.length - 1]
                      .locationsSuggested.length > 1
                  ) {
                    this._linesList[
                      this._linesList.length - 1
                    ].locationsSuggested = [
                      {
                        display_name: 'Seleccionar Locación',
                        sub_location_id: 0,
                      },
                    ].concat(data.locations);
                    this._linesList[this._linesList.length - 1].locationOption =
                      {
                        display_name: 'Seleccionar Locación',
                        sub_location_id: 0,
                      };
                  }
                  this.groupSkuLinesOld(this._linesList);
                });
            } else {
              this.groupSkuLinesOld(this._linesList);
            }
          }
        });
    }
  }
}
