import { createAction, props } from '@ngrx/store';
import {
  LoginFormModel,
  UserCognitoModel,
  TokenModel,
  CompleteNewPasswordModel,
  ForgotPasswordFormModel,
  ResetPasswordFormModel,
  ConfirmCodeFormModel,
  UsersModel,
  NewPasswordFormModel,
  UserConfigsWMSFilterModel,
} from '../models/interfaces';
import { UserConfigsWMSListModel } from '@kanzi-apes/kanzi-models';

export const initAuth = createAction('[Auth Page] Init');

export const login = createAction(
  '[Auth/API] Login',
  props<{ loginForm: LoginFormModel }>()
);

export const logout = createAction('[Auth/API] Logout');

export const loginSuccess = createAction('[Auth/API] Login Success');

export const loginFailure = createAction(
  '[Auth/API] Login Failure',
  props<{ error: any }>()
);

export const loginRedirect = createAction('[Auth/API] Login Redirect');

export const userProfileLoad = createAction('[Auth/API] User Profile Load');

export const userProfileSuccess = createAction(
  '[Auth/API] User Profile Success Load',
  props<{ user: UserCognitoModel }>()
);

export const completeNewPassword = createAction(
  '[Auth/API] Complete New Password',
  props<{ newPassword: NewPasswordFormModel }>()
);

export const CompleteNewPasswordSuccess = createAction(
  '[Auth/API] Complete New Password Success'
);

export const completeNewPasswordFailure = createAction(
  '[Auth/API] Complete New Password Failure',
  props<{ error: any }>()
);

export const completeNewPasswordActivated = createAction(
  '[Auth/API] Complete New Password Activated',
  props<{ userSignInData: any }>()
);

export const forgotPasswordActivated = createAction(
  '[Auth/API] Forgot Password Activated'
);

export const forgotPassword = createAction(
  '[Auth/API] Forgot Password',
  props<{ forgotLoginForm: ForgotPasswordFormModel }>()
);

export const forgotPasswordSuccess = createAction(
  '[Auth/API] Forgot Password Success'
);

export const forgotPasswordFailure = createAction(
  '[Auth/API] Forgot Password Failure',
  props<{ error: any }>()
);

export const resetPasswordActivated = createAction(
  '[Auth/API] Reset Password Activated',
  props<{ resendCode: boolean }>()
);

export const resetPassword = createAction(
  '[Auth/API] Reset Password',
  props<{ resetLoginForm: ResetPasswordFormModel }>()
);

export const resetPasswordSuccess = createAction(
  '[Auth/API] Reset Password Success',
  props<{ deliveryDetails: any }>()
);

export const resetPasswordFailure = createAction(
  '[Auth/API] Reset Password Failure',
  props<{ error: any }>()
);

export const resendCode = createAction(
  '[Auth/API] Resend Code',
  props<{ resetLoginForm: ResetPasswordFormModel }>()
);

export const resendCodeSuccess = createAction(
  '[Auth/API] Resend Code Success',
  props<{ deliveryDetails: any }>()
);

export const resendCodeFailure = createAction(
  '[Auth/API] Resend Code Failure',
  props<{ error: any }>()
);

export const confirmCodeActivated = createAction(
  '[Auth/API] Confirm Code Activated'
);

export const confirmCode = createAction(
  '[Auth/API] Confirm Code',
  props<{ confirmCodeForm: ConfirmCodeFormModel }>()
);

export const confirmCodeSuccess = createAction(
  '[Auth/API] Confirm Code Success',
  props<{ code: any }>()
);

export const confirmCodeFailure = createAction(
  '[Auth/API] Confirm Code Failure',
  props<{ error: any }>()
);

export const loadUser = createAction(
  '[Auth/API] Load User',
  props<{ email: string }>()
);

export const loadUserSuccess = createAction(
  '[Auth/API] Load User Success',
  props<{ user: UsersModel | null }>()
);

export const loadUserFailure = createAction(
  '[Auth/API] Load User Failure',
  props<{ error: any }>()
);

export const loginFormActivated = createAction(
  '[Auth/API] Login Form Activated'
);

export const loadUserConfigsWMS = createAction(
  '[Auth/API] Load User Configs WMS',
  props<{ userConfigFilters: UserConfigsWMSFilterModel }>()
);

export const loadUserConfigsWMSSuccess = createAction(
  '[Auth/API] Load User Configs WMS Success',
  props<{ userConfig: UserConfigsWMSListModel }>()
);

export const loadUserConfigsWMSFailure = createAction(
  '[Auth/API] Load User Configs WMS Failure',
  props<{ error: any }>()
);
