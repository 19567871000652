import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/*It implements the ControlValueAccessor interface and provides the necessary methods to be used as a
  form control 
*/
@Component({
  selector: 'kanzi-file-upload',
  templateUrl: './kanzi-file-upload.component.html',
  styleUrls: ['./kanzi-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: KanziFileUploadComponent,
      multi: true,
    },
  ],
})
export class KanziFileUploadComponent implements OnInit, ControlValueAccessor {
  @Input() multiple:boolean=false;
  @Input() activeBtnSubmit:boolean = true;
  @Input() accept:string = '*'
  @Input() disabled:boolean=false;
  
  private onChange!: Function;
  files: File[] = [];

  constructor() {}

  ngOnInit(): void {}

  public resetFiles(): void {
    this.files = [];  // Esto vacía el array de archivos
  }
  writeValue(value: File[]): void {
    //LOAD DATA FORM
   // this.files = value ? value : [];
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  onFileSelected(event: any) {
    const files = event.target.files;    
    if (files.length > 0) {
      this.files.push(files[0]);
      if(!this.activeBtnSubmit){
        this.onChange(this.multiple?this.files:this.files[0]);
      }
    }
  }

  onUpload(){
    this.onChange(this.multiple?this.files:this.files[0]);
    this.files=[];
  }

  onDeleteSelected(file: File) {
    this.files = this.files.filter((i) => i.name !== file.name);
    if(!this.activeBtnSubmit){
      this.onChange((this.multiple && this.files.length>0)?this.files:null);
    }
  }
}
