//import { AtelesColorSchema } from '@ateles-iot-project/ateles-models';
import { createAction, props } from '@ngrx/store';

export const initLayout = createAction('[Layout Page] Init');

export const actionMainMenu = createAction(
  '[Layout/API] Action MainMenu',
  props<{ action: string }>()
);

export const actionRightMenu = createAction(
  '[Layout/API] Action Right Menu',
  props<{ action: string }>()
);

export const openMenuMain = createAction('[Layout/API] Open MainMenu');

export const closeMenuMain = createAction('[Layout/API] Close MainMenu');

export const showProgressBar = createAction('[Layout/API] Show Progress Bar');

export const hideProgressBar = createAction('[Layout/API] Hide Progress Bar');

export const activateCustomerParent = createAction(
  '[Layout/API] Customer Activate Parent'
);

export const deactivateCustomerParent = createAction(
  '[Layout/API] Customer Deactivate Parent'
);

/* export const colorScheme = createAction(
  '[Layout/API] Color layout',
  props<{ colorSchema: AtelesColorSchema }>()
); */
