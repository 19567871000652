import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  CompleteNewPasswordModel,
  ConfirmCodeFormModel,
  ForgotPasswordFormModel,
  LoginChangePasswordRequired,
  LoginCognitoSuccess,
  LoginFormModel,
  NewPasswordFormModel,
  ResetPasswordFormModel,
} from '../models/interfaces';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of, tap, expand, from } from 'rxjs';
import { AlertService } from '@kanzi-apes/kanzi-alerts';
import * as AuthActions from './auth.actions';
import * as AuthFeature from './auth.reducer';
import { Store } from '@ngrx/store';
import { AuthService } from '../services/auth/auth.service';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

@Injectable()
export class AuthEffects {
  /**
   * Effect to redirect to login page when user logs out.
   */
  loginRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginRedirect, AuthActions.logout),
        tap(() => {
          this.router.navigate(['/auth/login']);
        })
      );
    },
    { dispatch: false }
  );

  /**
   * Effect to login user.
   */
  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.login),
      map((action) => action.loginForm),
      exhaustMap((auth: LoginFormModel) =>
        from(
          this.authService.loginUser({
            username: auth.username,
            password: auth.password,
          })
        ).pipe(
          tap((response: any) => {
            if (
              response &&
              response.userSession &&
              response.userSession.isValid()
            ) {
              localStorage.setItem(
                'KanziWMSUserToken',
                response.userSession.getIdToken().getJwtToken()
              );
            }
          }),
          map((userResponse: any) => {
            if (
              userResponse &&
              userResponse.challengeName &&
              userResponse.challengeName === 'NEW_PASSWORD_REQUIRED'
            ) {
              return AuthActions.completeNewPasswordActivated({
                userSignInData: userResponse,
              });
            } else {
              let currentUser = this.authService.currentAuthenticatedUser();
              if (currentUser) {
                return AuthActions.loginSuccess();
              } else {
                return AuthActions.loginFailure({ error: 'User not found' });
              }
            }
          }),
          catchError((error) => of(AuthActions.loginFailure({ error: error })))
        )
      )
    );
  });

  completeNewPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.completeNewPassword),
      map((action) => action.newPassword),
      exhaustMap((newPwd: NewPasswordFormModel) =>
        from(this.authService.changeNewPassword(newPwd)).pipe(
          tap((response: any) => {
            if (
              response &&
              response.userSession &&
              response.userSession.isValid()
            ) {
              localStorage.setItem(
                'KanziWMSUserToken',
                response.userSession.getAccessToken().getJwtToken()
              );
            }
          }),
          map((userResponse: any) => {
            let currentUser = this.authService.currentAuthenticatedUser();
            if (currentUser) {
              return AuthActions.CompleteNewPasswordSuccess();
            } else {
              return AuthActions.completeNewPasswordFailure({
                error: 'Error al actualizar la contraseña',
              });
            }
          }),
          catchError((error) =>
            of(AuthActions.completeNewPasswordFailure(error))
          )
        )
      )
    );
  });

  resetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.resetPassword),
      map((action) => action.resetLoginForm),
      exhaustMap((resetLoginForm: ResetPasswordFormModel) =>
        from(this.authService.restorePassword(resetLoginForm.username)).pipe(
          map((forgotLoginResponse: any) => {
            return AuthActions.resetPasswordSuccess({
              deliveryDetails: forgotLoginResponse.CodeDeliveryDetails,
            });
          }),
          catchError((error) => of(AuthActions.resetPasswordFailure({ error })))
        )
      )
    );
  });

  /**
   * Effect to login success.
   */
  loginSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap(() => this.router.navigate(['/']))
      );
    },
    { dispatch: false }
  );

  /**
   * Effect to logout user.
   */
  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.logout),
        map((action) =>
          from(this.authService.logoutUser()).pipe(
            map((userResponse: any) => {}),
            tap(() => this.router.navigate(['/auth/login'])),
            catchError((error) => of(AuthActions.loginFailure(error)))
          )
        )
      );
    },
    { dispatch: false }
  );

  completeNewPasswordSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.CompleteNewPasswordSuccess),
        tap(() => {
          this.router.navigate(['/']);
          this.alertService.success('Contraseña Actualizada.');
        })
      );
    },
    { dispatch: false }
  );

  loginFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginFailure),
        tap((action) => this.alertService.error(action.error.message))
      );
    },
    { dispatch: false }
  );

  completeNewPasswordFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.completeNewPasswordFailure),
        tap((action) => this.alertService.error(action.error.message))
      );
    },
    { dispatch: false }
  );

  resetPasswordSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.resetPasswordSuccess),
        map((action) => action.deliveryDetails),
        tap((deliveryDetails) =>
          this.alertService.success(
            `Código de Verificación Enviado al Email: ${deliveryDetails.Destination}`
          )
        )
      );
    },
    { dispatch: false }
  );

  resetPasswordSuccessRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.resetPasswordSuccess),
        tap(() => {
          this.store.dispatch(AuthActions.forgotPasswordActivated());
        })
      );
    },
    { dispatch: false }
  );

  forgotPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.forgotPassword),
      map((action) => action.forgotLoginForm),
      exhaustMap((forgotLoginForm: ForgotPasswordFormModel) =>
        from(this.authService.confirmNewPassword(forgotLoginForm)).pipe(
          map((forgotLoginResponse: any) => {
            return AuthActions.forgotPasswordSuccess();
          }),
          catchError((error) =>
            of(AuthActions.forgotPasswordFailure({ error }))
          )
        )
      )
    );
  });

  forgotPasswordSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.forgotPasswordSuccess),
        tap(() => this.alertService.success('Contraseña Actualizada.'))
      );
    },
    { dispatch: false }
  );

  forgotPasswordSuccessRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.forgotPasswordSuccess),
        tap(() => {
          this.store.dispatch(AuthActions.loginFormActivated());
        })
      );
    },
    { dispatch: false }
  );

  loadUserConfigWMS$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loadUserConfigsWMS),
      map((action) => action.userConfigFilters),
      exhaustMap((filters) =>
        from(this.authService.obtainConfigUser(filters)).pipe(
          map((userConfigWMS) => {
            return AuthActions.loadUserConfigsWMSSuccess({
              userConfig: userConfigWMS,
            });
          }),
          catchError((error) =>
            of(AuthActions.loadUserConfigsWMSFailure({ error }))
          )
        )
      )
    );
  });

  /* 

  

  resendCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.resendCode),
      map((action) => action.resetLoginForm),
      exhaustMap((resetLoginForm: ResetPasswordFormModel) =>
        from(Auth.resendSignUp(resetLoginForm.username)).pipe(
          map((forgotLoginResponse: any) => {
            return AuthActions.resendCodeSuccess({
              deliveryDetails: forgotLoginResponse.CodeDeliveryDetails,
            });
          }),
          catchError((error) => of(AuthActions.resendCodeFailure({ error })))
        )
      )
    );
  });

  confirmCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.confirmCode),
      map((action) => action.confirmCodeForm),
      exhaustMap((confirmCodeForm: ConfirmCodeFormModel) =>
        from(
          Auth.confirmSignUp(confirmCodeForm.username, confirmCodeForm.code)
        ).pipe(
          map((confirmCodeResponse: any) => {
            return AuthActions.confirmCodeSuccess({
              code: confirmCodeResponse,
            });
          }),
          catchError((error) => of(AuthActions.confirmCodeFailure({ error })))
        )
      )
    );
  }); */

  /*   

  
  loadUserTypeDispatch$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap((action) => {
          console.log(action);
          if (action.dataAuth.user.email) {
            this.store.dispatch(
              AuthActions.loadUser({ email: action.dataAuth.user.email })
            );
          }
        })
      );
    },
    { dispatch: false }
  );

  

  

  confirmCodeSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.confirmCodeSuccess),
        tap(() => this.alertService.success('Usuario Activado.'))
      );
    },
    { dispatch: false }
  );

  resetPasswordSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.resetPasswordSuccess),
        map((action) => action.deliveryDetails),
        tap((deliveryDetails) =>
          this.alertService.success(
            `Código de Verificación Enviado al Email: ${deliveryDetails.Destination}`
          )
        )
      );
    },
    { dispatch: false }
  );

  resetPasswordSuccessRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.resetPasswordSuccess),
        tap(() => {
          this.store.dispatch(AuthActions.forgotPasswordActivated());
        })
      );
    },
    { dispatch: false }
  );

  resendCodeSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.resendCodeSuccess),
        map((action) => action.deliveryDetails),
        tap((deliveryDetails) =>
          this.alertService.success(
            `Código de Verificación Enviado al Email: ${deliveryDetails.Destination}`
          )
        )
      );
    },
    { dispatch: false }
  );

  resendCodeSuccessRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.resendCodeSuccess),
        tap(() => {
          this.store.dispatch(AuthActions.forgotPasswordActivated());
        })
      );
    },
    { dispatch: false }
  );

  forgotPasswordFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.forgotPasswordFailure),
        tap((action) => {
          switch (action.error.code) {
            case 'ExpiredCodeException':
              this.alertService.error('Código Expirado');
              break;

            default:
              break;
          }
        })
      );
    },
    { dispatch: false }
  );

  confirmCodeFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.confirmCodeFailure),
        tap((action) => {
          switch (action.error.code) {
            case 'ExpiredCodeException':
              this.alertService.error('Código Expirado');
              break;

            default:
              break;
          }
        })
      );
    },
    { dispatch: false }
  );

  loginFailureCheckPwd$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginFailure),
        map((action) => action.error),
        tap((error: any) => {
          switch (error.code) {
            case 'PasswordResetRequiredException':
              this.store.dispatch(AuthActions.forgotPasswordActivated());
              break;
            case 'UserNotConfirmedException':
              this.store.dispatch(AuthActions.forgotPasswordActivated());
              break;
            default:
              break;
          }
        })
      );
    },
    { dispatch: false }
  );

  resetPasswordFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.resetPasswordFailure),
        tap((action) => {
          switch (action.error.code) {
            case 'UserNotFoundException':
              this.alertService.error('Email no registrado.');
              break;

            default:
              break;
          }
        })
      );
    },
    { dispatch: false }
  );

  resendCodeFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.resendCodeFailure),
        tap((action) => {
          switch (action.error.code) {
            case 'UserNotFoundException':
              this.alertService.error('Email no registrado.');
              break;

            default:
              break;
          }
        })
      );
    },
    { dispatch: false }
  ); */

  constructor(
    private readonly actions$: Actions,
    private router: Router,
    private alertService: AlertService,
    private store: Store<AuthFeature.AuthState>,
    private authService: AuthService
  ) {}
}
