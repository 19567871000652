import { Route } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { canMatchFn, canActivateFn } from '@kanzi-apes/kanzi-authenticator';
import { importProvidersFrom } from '@angular/core';
import * as LocationState from '@kanzi-apes/kanzi-locations';
import { StoreModule } from '@ngrx/store';

const kanziTitle = 'Kanzi WMS';

export const appRoutes: Route[] = [
  {
    path: '',
    component: LayoutComponent,
    providers: [
      importProvidersFrom(
        StoreModule.forFeature(
          LocationState.LOCATIONS_FEATURE_KEY,
          LocationState.locationsReducer
        )
      ),
    ],
    title: kanziTitle,
    data: {
      breadcrumb: 'Inicio',
    },
    canActivate: [canActivateFn],
    children: [
      {
        path: 'data-loader',
        title: kanziTitle + ' - Data Loader',
        canMatch: [canMatchFn],
        data: {
          breadcrumb: 'Carga de datos (Actividad)',
        },
        loadComponent: () =>
          import('@kanzi-apes/kanzi-data-loader-wms').then(
            (m) => m.KanziDataLoaderWmsComponent
          ),
      },
      {
        path: 'administration',
        title: kanziTitle + ' - Administration',
        canMatch: [canMatchFn],
        data: {
          breadcrumb: 'Administración',
        },
        children: [
          {
            path: 'users',
            loadChildren: () =>
              import('@kanzi-apes/kanzi-wms-users').then(
                (m) => m.kanziWmsUsersAdministrationRoutes
              ),
            title: kanziTitle + ' - Users',
            canMatch: [canMatchFn],
            data: {
              breadcrumb: 'Usuarios',
            },
          },
          {
            path: 'locations',
            loadChildren: () =>
              import('@kanzi-apes/kanzi-locations').then(
                (m) => m.kanziLocationManagementRoutes
              ),
            title: kanziTitle + ' - Locations',
            canMatch: [canMatchFn],
            data: {
              breadcrumb: 'Ubicaciones',
            },
          },
        ],
      },
      {
        path: 'users',
        loadChildren: () =>
          import('@kanzi-apes/kanzi-wms-users').then(
            (m) => m.kanziWmsUsersRoutes
          ),
        title: kanziTitle + ' - Users',
        canMatch: [canMatchFn],
        data: {
          breadcrumb: 'Usuarios',
        },
      },
      {
        path: 'third-parties',
        loadChildren: () =>
          import('@kanzi-apes/kanzi-contacts').then(
            (m) => m.kanziThirdPartiesRoutes
          ),
        title: kanziTitle + ' - Third Parties',
        canMatch: [canMatchFn],
        data: {
          breadcrumb: 'Terceros',
        },
      },
      {
        path: 'products',
        loadChildren: () =>
          import('@kanzi-apes/kanzi-products').then(
            (m) => m.kanziProductsRoutes
          ),
        title: kanziTitle + ' - Products',
        canMatch: [canMatchFn],
        data: {
          breadcrumb: 'Productos',
        },
      },
      {
        path: 'operations',
        loadChildren: () =>
          import('@kanzi-apes/kanzi-operations-wms').then(
            (m) => m.kanziOperationsWmsRoutes
          ),
        title: kanziTitle + ' - Operations',
        canMatch: [canMatchFn],
        data: {
          breadcrumb: 'Géstion de pedidos',
        },
      },

      {
        path: 'reception',
        loadChildren: () =>
          import('@kanzi-apes/kanzi-reception-wms').then(
            (m) => m.kanziReceptionWmsRoutes
          ),
        title: kanziTitle + ' - Reception',
        canMatch: [canMatchFn],
        data: {
          breadcrumb: 'Órdenes de Recepción'
        },
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('@kanzi-apes/kanzi-authenticator').then(
        (m) => m.kanziAuthenticatorRoutes
      ),
    title: kanziTitle + ' - Login',
  },

  {
    path: '**',
    redirectTo: '',
  },
];
