<p-table
  [value]="productsSelected()"
  [rows]="10"
  [paginator]="true"
  [totalRecords]="0"
  [responsive]="true"
  [styleClass]="'kanzi-ui'"
  [loading]="false"
  [resizableColumns]="true"
  editMode="row"
  dataKey="sku_id"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th>{{ 'Nombre' | translate }}</th>
      <th>{{ 'Código de barras' | translate }}</th>
      <th>{{ 'Referencia' | translate }}</th>
      <th style="width: 15em">{{ 'Cantidad' | translate }}</th>
      <th>{{ 'Moneda' | translate }}</th>
      <th>{{ 'Precio Unitario' | translate }}</th>
      <th style="width: 8em">Acciones</th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowData
    let-editing="editing"
    let-ri="rowIndex"
  >
    <tr [pEditableRow]="rowData" id="rowsDatas__tr_overflow">
      <td>
        {{ rowData.display_name }}
      </td>
      <td>{{ rowData.barcode }}</td>
      <td>{{ rowData.reference_id }}</td>
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              type="number"
              pInputText
              [(ngModel)]="rowData.amount"
              placeholder="0.0"
              [ngModelOptions]="{ standalone: true }"
              [style]="{ width: '8rem' }"
              [min]="1"
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.amount }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        {{ rowData.purchase_coin }}
      </td>
      <td>
        {{ rowData.purchase_price }}
      </td>

      <td style="text-align: center">
        @if(!editing){
        <button
          pButton
          type="button"
          pInitEditableRow
          icon="pi pi-pencil"
          class="p-button-info mr-2 p-button-text"
        ></button>
        <button
          pButton
          type="button"
          icon="pi pi-trash"
          class="p-button-danger mr-2 p-button-text"
          (click)="onDeleteRowData(rowData)"
        ></button>
        }@else {
        <button
          pButton
          type="button"
          pSaveEditableRow
          icon="pi pi-check"
          class="p-button-success mr-2 p-button-text"
        ></button>
        <button
          pButton
          type="button"
          pCancelEditableRow
          icon="pi pi-times"
          class="p-button-danger p-button-text"
        ></button>
        }
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="6">
        {{ 'Registros no encontrados.' | translate }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    <div class="lex justify-content-start">
      <button
        icon="pi pi-plus"
        type="button"
        class="p-button-help mr-3"
        pButton
        pRipple
        label="{{ 'Ingresar producto' | translate }}"
        (click)="onDisplayDialogProducts()"
      ></button>
      <button
        type="button"
        icon="pi pi-file"
        class="mr-3"
        pButton
        pRipple
        label="{{ 'Subir lista de productos' | translate }}"
        (click)="displayDialogSkuList=true"
      ></button>
    </div>
  </ng-template>
</p-table>
<p-toast></p-toast>

<!-- sku list -->
<p-dialog
  header="Ingresar la lista de producto y cantidad"
  [(visible)]="displayDialogSkuList"
  [responsive]="true"
  showEffect="fade"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '30vw' }"
  [modal]="true"
  [draggable]="false"
>
  <div class="field">
    <label for="skulist"
      >Ingresa en el formato por línea: (Referencia, Cantidad) del
      producto</label
    >
    <textarea
      pInputTextarea
      name="skulist"
      #skulist="ngModel"
      placeholder="Referencia, Cantidad"
      class="w-full"
      [rows]="10"
      [(ngModel)]="productsListText"
      [ngModelOptions]="{ standalone: true }"
    ></textarea>
  </div>
  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      pRipple
      class="p-button-text p-button-danger mr-2"
      icon="pi pi-times"
      label="{{ 'Borrar' | translate }}"
      (click)="onDeleteList()"
    ></button>
    <button
      type="button"
      pButton
      pRipple
      class="p-button-text p-button-success"
      icon="pi pi-save"
      label="{{ 'Guardar' | translate }}"
      (click)="saveListProducts(productsListText)"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="displayDialogProducts"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '55vw', 'min-height': '50vh' }"
  [modal]="true"
  [draggable]="false"
  [maximizable]="true"
  header="{{ 'Listado de Sku' | translate }}"
>
  <div class="list-container">
    <p-dataView
      [value]="queryProducts.data()"
      [styleClass]="'pendingTask'"
      [loading]="queryProducts.isLoading()"
      emptyMessage="No se encontraron resultados"
    >
      <ng-template pTemplate="header">
        <div class="p-input-icon-left w-full">
          <i class="pi pi-search"></i>
          <input
            id="skuSearch"
            name="skuSearch"
            type="text"
            pInputText
            placeholder="Buscar por código de barras..."
            class="w-full"
            [(ngModel)]="barCodeFilter"
          />
        </div>
      </ng-template>

      <ng-template let-products pTemplate="listItem">
        @for (product of products; track product.id){ @if
        (queryProducts.isLoading()) {
        <div class="product-item">
          <p-skeleton size="1rem" styleClass="mr-2"></p-skeleton>
          <div class="image-container">
            <p-skeleton size="5rem"></p-skeleton>
          </div>
          <div class="product-list-detail">
            <p-skeleton
              width="8rem"
              height="2rem"
              styleClass="mb-2 ml-2"
            ></p-skeleton>
            <p-skeleton
              width="15rem"
              height="1rem"
              styleClass="product-category ml-2"
            ></p-skeleton>
          </div>
          <div class="product-list-action">
            <div class="field">
              <p-skeleton
                width="13rem"
                height="2.5rem"
                styleClass="mb-2 mr-4"
              ></p-skeleton>
            </div>
          </div>
        </div>
        }@else {
        <div class="product-item">
          <p-checkbox
            name="skus"
            inputId="sku"
            class="mr-3"
            [binary]="true"
            [(ngModel)]="product.isSelected"
            [value]="product.isSelected"
          ></p-checkbox>
          <div class="image-container">
            <img
              [src]="
                product?.image_files.length
                  ? product?.image_files[0]
                  : defaultImage
              "
              [alt]="product.name"
              onerror="this.onerror=null;this.src='assets/images/backgrounds/not-image-400x300.png'"
              class="product-image"
            />
          </div>
          <div class="product-list-detail">
            <h5 class="mb-2 text-indigo-500">{{ product.name }}</h5>
            <i class="pi pi-tag product-category-icon"></i>
            <span class="product-category mr-2"
              ><strong>Referencia: </strong>{{ product.reference_id }}</span
            >
            <span class="product-category"
              ><strong>Código de barras:</strong>{{ product.barcode }}</span
            >
          </div>
          <div class="product-list-action">
            <div class="field">
              @if(product.isSelected){
              <p-inputNumber
                [showButtons]="true"
                placeholder="Cantidad"
                incrementButtonClass="p-button-text p-button-sm p-button-help"
                decrementButtonClass="p-button-text p-button-sm p-button-help"
                [min]="1"
                [minFractionDigits]="1"
                [(ngModel)]="product.amount"
                [ngModelOptions]="{ standalone: true }"
              >
              </p-inputNumber>
              }@else {
              <p-inputNumber
                [showButtons]="true"
                placeholder="Cantidad"
                incrementButtonClass="p-button-text p-button-sm p-button-help"
                decrementButtonClass="p-button-text p-button-sm p-button-help"
                [min]="1"
                [minFractionDigits]="1"
                [disabled]="true"
              >
              </p-inputNumber>
              }
            </div>
          </div>
        </div>
        } }
      </ng-template>
    </p-dataView>
  </div>

  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      pRipple
      class="p-button-text p-button-secondary"
      icon="pi pi-times"
      label="{{ 'Cerrar' | translate }}"
      (click)="displayDialogProducts = false"
    ></button>
    <button
      type="button"
      pButton
      pRipple
      class="p-button-text p-button-success"
      icon="pi pi-save"
      label="{{ 'Guardar' | translate }}"
      (click)="onSaveProducts(queryProducts.data())"
    ></button>
  </ng-template>
</p-dialog>
