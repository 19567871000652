/* eslint-disable @nx/enforce-module-boundaries */
import {
  MaintenanceBudgetOrderLineListModel,
  MaintenanceBudgetOrderLineModel,
  MaintenanceBugetLineCreateModel,
} from '@kanzi-apes/kanzi-models';
import { currencyFormatter } from './util.functions';

const calculateLines = (
  linesFilter:
    | MaintenanceBugetLineCreateModel[]
    | MaintenanceBudgetOrderLineModel[]
    | undefined,
  isQuantity: boolean
) => {
  if (!linesFilter || linesFilter.length === 0)
    return currencyFormatter.format(0);

  const total = linesFilter
    .map(
      (item) =>
        (item.params?.costs?.unit_price_disc ??
          0 * (isQuantity ? item.quantity ?? 0 : item.amount ?? 0)) as number
    )
    .reduce((p, t) => p + t);

  return currencyFormatter.format(total);
};

export const calculateTotalIvaSkuFn = (
  lines: MaintenanceBudgetOrderLineListModel | null,
  isQuantity: boolean
) => {
  if(!lines) return currencyFormatter.format(0);
  const linesFilter = lines?.results.filter((b) => b.params?.iva === 0);
  return calculateLines(linesFilter, isQuantity);
};

export const calculateTotalIvaSkuWithoutBudgetFn = (
  lines: MaintenanceBugetLineCreateModel[] | null,
  isQuantity: boolean
) => {
  if(!lines) return currencyFormatter.format(0);
  const linesFilter = lines?.filter((b) => b.params?.iva === 0);
  return calculateLines(linesFilter, isQuantity);
};
