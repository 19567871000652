export interface ProductLinesValidatorModel {
  sku_display_name: string;
  sku_id: number;
  sku_external_id: string;
  sku_color?: string;
  sku_size?: string;
  box_number: number;
  status: string;
  total: number;
  total_verified: number;
  items: string[];
  statusItem: string;
}

export interface ProductExtraLinesValidatorModel {
  sku_display_name: string;
  sku_id: number;
  sku_external_id: string;
  sku_color?: string;
  sku_size?: string;
  total_extra: number;
  items: string[];
  status:string;
}
