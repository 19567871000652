import { Route } from '@angular/router';

export const kanziAuthenticatorRoutes: Route[] = [
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/kanzi-login/kanzi-login.component').then(
        (m) => m.KanziLoginComponent
      ),
  },
];
