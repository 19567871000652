/* eslint-disable @angular-eslint/component-selector */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {
  AddComponent,
  ConfigDownloadDataModel,
  ConfigTableModel,
  CustomerModel,
  DataListModel,
  FiltersModel,
  ListResults,
} from '@kanzi-apes/kanzi-models';
import * as FileSaver from 'file-saver';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import { LazyLoadEvent, MenuItem, SortEvent } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { EMPTY, expand } from 'rxjs';
import { DynamicTableService } from '../../services/dynamic-table.service';
import { ProgressBarService } from '../../services/progress-bar.service';

/**
 * @author Hugo Andrés Escobar Ciceri
 * @version 5.0.0
 *
 * Component to create a configurable table.
 */
@Component({
  selector: 'kanzi-dynamic-table',
  templateUrl: './kanzi-dynamic-table.component.html',
  styleUrls: ['./kanzi-dynamic-table.component.scss'],
})
export class KanziDynamicTableComponent implements OnInit, OnChanges {
  @ViewChild('dt') tableComponent!: Table;
  @Input() dataSource: DataListModel | ListResults<any> | null;
  @Input() hasNextPage: boolean = false;
  @Input()
  set colSource(cols: any[]) {
    this.colSourceTransform = cols.map((col) => {
      if ((col.field as string).toLowerCase().includes('status')) {
        return {
          ...col,
          typeData: 'tag',
          pipe: false,
        };
      }

      return col;
    });
  }
  get colSource() {
    return this.colSourceTransform;
  }
  @Input() configs: ConfigTableModel | null;
  @Input() pending: boolean | null;
  @Input() customer: CustomerModel | null;
  @Input() filters: FiltersModel | null;
  @Input() dateFilterOption: string;
  @Input() dynamicComponent: AddComponent | null;
  @Input() isDownloadAll = false;
  @Input() downloadConfigs: ConfigDownloadDataModel | null = null;
  @Input() transformDataFn?: (data: any) => any;
  @Output() paginationEvent = new EventEmitter<FiltersModel>();
  @Output() selectEvent = new EventEmitter<any>();
  @Output() editEvent = new EventEmitter<any>();
  @Output() deleteEvent = new EventEmitter<any>();
  @Output() unsubcribeEvent = new EventEmitter<any>();
  @Output() filterEvent = new EventEmitter<FiltersModel>();
  @Output() clearEvent = new EventEmitter<boolean>();
  @Output() multiselectItemsEvent = new EventEmitter<any[]>();
  @Output() expandRowEvent = new EventEmitter<any>();
  @Output() loadDatasetEvent = new EventEmitter<FiltersModel>();
  @Output() clearAllFiltersColumns = new EventEmitter<boolean>();
  @Output() sortCustomField = new EventEmitter<FiltersModel>();
  @Output() searchCustomFilter = new EventEmitter<FiltersModel>();
  @Output() clickButtonCustom = new EventEmitter<any>();
  @Output() rowSelect = new EventEmitter<any>();

  rangeDateCalendar: Date[] | null = null;
  colSourceTransform: any[] = [];
  dataListTable: any[] = [];
  emptyMsg = 'Registros no encontrados.';
  showFilter = false;
  selectedItems: any[] = [];
  filteredTotals = 0;
  exportColumns: any[] = [];
  exportExcelColumns: string[] = [];
  searchFilter: UntypedFormControl;
  items: MenuItem[] = [];
  formFilters!: UntypedFormGroup;
  columSelect: any;
  objAux: any = {};
  colsOptionsFilter: any[] = [];
  downloadData: any[] = [];
  downloadCount = 1;
  isVisibleProgressBar = false;

  constructor(
    private fb: UntypedFormBuilder,
    private readonly downloadService: DynamicTableService,
    public readonly progressBarService: ProgressBarService
  ) {
    this.dataSource = null;
    this.colSource = [];
    this.configs = null;
    this.pending = false;
    this.customer = null;
    this.filters = null;
    this.dateFilterOption = '';
    this.dynamicComponent = null;
    this.searchFilter = this.fb.control('');
  }

  ngOnInit() {
    this.items = [
      {
        label: 'Descargar CSV',
        icon: 'pi pi-file-o',
        visible: !this.isDownloadAll,
        command: () => {
          this.tableComponent.exportCSV();
        },
      },
      {
        label: 'Descargar XLS',
        icon: 'pi pi-file-excel',
        command: () => {
          if (this.isDownloadAll && this.downloadConfigs) {
            this.getAllData(
              {
                path: this.downloadConfigs?.path,
                filters: this.downloadConfigs?.filters,
                count: this.downloadConfigs.count,
              },
              'XLS'
            );
          } else {
            if (this.dataSource?.results) {
              this.exportExcel(this.dataSource?.results);
            }
          }
        },
      },
      {
        label: 'Descargar PDF',
        icon: 'pi pi-file-pdf',
        command: () => {
          if (this.isDownloadAll && this.downloadConfigs) {
            this.getAllData(
              {
                path: this.downloadConfigs?.path,
                filters: this.downloadConfigs?.filters,
                count: this.downloadConfigs.count,
              },
              'PDF'
            );
          } else {
            if (this.dataSource?.results) {
              this.exportPdf(this.dataSource?.results);
            }
          }
        },
      },
    ];

    this.searchFilter.valueChanges.subscribe((query) => {
      if (this.configs?.lazy) {
        this.searchCustomFilter.emit({
          ...this.filters,
          search: query,
        });
      }
    });
  }

  private getAllData(
    dataDownload: { path: string; filters: any; count: number },
    type: 'PDF' | 'XLS'
  ) {
    this.isVisibleProgressBar = true;
    const totalPacket = Math.ceil(dataDownload.count / 1000);
    this.downloadService
      .getAllData(
        dataDownload.path,
        `${this.downloadCount}/${totalPacket}`,
        dataDownload.filters,
        null
      )
      .pipe(
        expand(({ next }: any) =>
          next
            ? this.downloadService.getAllData(
                dataDownload.path,
                `${this.downloadCount}/${totalPacket}`,
                null,
                next
              )
            : EMPTY
        )
      )
      .subscribe({
        next: (response: unknown) => {
          this.downloadData = this.downloadData.concat(
            (<DataListModel>response).results
          );
          this.downloadCount += 1;
        },
        complete: () => {
          this.isVisibleProgressBar = false;
          this.downloadCount = 1;
          const dataTransform = this.transformDataFn
            ? this.downloadData.map(this.transformDataFn)
            : this.downloadData;
          if (type === 'PDF') {
            this.exportPdf(dataTransform);
          } else if (type === 'XLS') {
            this.exportExcel(dataTransform);
          }
        },
      });
  }

  sortFunction(event: SortEvent) {
    if (!this.configs?.lazy && event) {
      const filtersDate = ['modified', 'created', 'cut_date'];
      event.data?.sort((data1, data2) => {
        let value1: any = data1[event.field!];
        let value2: any = data2[event.field!];
        let result = null;

        if (filtersDate.includes(event.field!)) {
          value1 = new Date(moment(value1, 'DD-MM-YYYY').format('L'));
          value2 = new Date(moment(value2, 'DD-MM-YYYY').format('L'));
        }

        if (value1 == null && value2 != null) result = -1;
        else if (value1 != null && value2 == null) result = 1;
        else if (value1 == null && value2 == null) result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string')
          result = value1.localeCompare(value2);
        else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

        return event.order! * result;
      });
    }
  }

  customSort(col: any) {
    if (this.configs?.lazy && col['orderingField']) {
      if (this.filters?.ordering === col['orderingField']) {
        this.sortCustomField.emit({
          ...this.filters,
          ordering: '',
        });
      } else {
        this.sortCustomField.emit({
          ...this.filters,
          ordering: col['orderingField'],
        });
      }
    }
  }

  onSearch(value: any, matchMode: any) {
    if (!this.configs?.lazy) {
      this.tableComponent.filterGlobal(value, matchMode);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('🚀 ~ ngOnChanges ~ changes', changes);
    if (this.dataSource) {
      this.dataListTable = ([] as any[]).concat(this.dataSource.results);
      this.downloadConfigs = {
        ...this.downloadConfigs,
        count: this.dataSource.count,
      } as ConfigDownloadDataModel;
    } else {
      this.dataListTable = [];
    }
    if (this.colSource) {
      this.colsOptionsFilter = this.colSource.filter(
        (item) => item.filterOptions && item.filterOptions.length > 0
      );
      const fields = this.colsOptionsFilter.map((item) => item.field);
      fields.forEach((columns) => {
        this.objAux[columns] = [];
      });
      this.formFilters = this.fb.group(this.objAux);
      this.formFilters.valueChanges.subscribe((value) => {
        if (this.columSelect) {
          this.tableComponent.filter(
            value[this.columSelect.field],
            this.columSelect.field,
            'in'
          );
        }
      });
      this.exportColumns = this.colSource.map((col) => ({
        title: col.header,
        dataKey: col.field,
      }));
      this.exportExcelColumns = [];
      this.exportExcelColumns = this.colSource.map((col) => col.field);
      this.selectedItems = [];
    }
  }

  onRowExpand(event: any) {
    this.expandRowEvent.emit(event.data);
  }

  get itemsSelected(): any[] {
    return this.selectedItems;
  }

  set itemsSelected(items: any[]) {
    this.selectedItems = items;
    this.multiselectItemsEvent.emit(items);
  }

  /**
   *
   * @param event {LazyLoadEvent}
   */
  loadLazy(event: TableLazyLoadEvent) {
    console.log('🚀 ~ loadLazy ~ event', event);
    if (event && event.first !== undefined && event.rows !== undefined) {
      const pag = event.first / event.rows! + 1;
      this.filters = {
        ...this.filters,
        page: pag,
        page_size: this.configs?.rows,
        customer_id: this.customer ? this.customer.id : 0,
        search:
          event.globalFilter === null ? '' : (event.globalFilter as string),
        first: event.first,
      };
      this.paginationEvent.emit(this.filters);
    }
  }

  /**
   *
   * @param el {any}
   *
   * Function to emit select item action with item data.
   */
  onRowSelect(el: any) {
    this.selectEvent.emit(el);
  }

  /**
   *
   * @param el {any}
   *
   * Function to emit select item action with item data.
   */
  onRowSelected(el: any) {
    this.rowSelect.emit(el.data);
  }

  /**
   *
   * @param el {any}
   *
   * Function to emit delete action with the item data.
   */
  onRowEdit(el: any) {
    this.editEvent.emit(el);
  }

  /**
   *
   * @param el {any}
   *
   * Function to emit delete action with the item data.
   */
  onRowDelete(el: any) {
    this.deleteEvent.emit(el);
  }
  onRowUnsubscribe(el: any) {
    this.unsubcribeEvent.emit(el);
  }
  onFilter(filters: any) {
    if (filters) {
      this.filteredTotals = filters.filteredValue.length;
    }
  }

  onClickButtonCustom(el: any) {
    this.clickButtonCustom.emit(el);
  }

  onFilterDateTable(dates: Date[]) {
    if (dates[1]) {
      const initDate = moment(dates[0]).format('MM/DD/YYYY');
      const endDate = moment(dates[1]).format('MM/DD/YYYY');

      this.filters =
        this.dateFilterOption && this.dateFilterOption === 'modified'
          ? {
              ...this.filters,
              page: 1,
              page_size: 200,
              modified_min: initDate,
              modified_max: endDate,
            }
          : {
              ...this.filters,
              page: 1,
              page_size: 200,
              created_min: initDate,
              created_max: endDate,
            };

      this.filterEvent.emit(this.filters);
    }
  }

  clearFilters(check: boolean, dt: any) {
    let checked: boolean = check;
    if (checked) {
      dt.reset();
      this.tableComponent.filters = {};
      this.columSelect = null;
      this.showFilter = false;
      this.filteredTotals = 0;
      this.rangeDateCalendar = null;
      this.formFilters?.reset();
      this.searchFilter.setValue('');
      this.clearEvent.emit(check);
    }
    checked = false;
  }

  clearAllFilters(dt: any) {
    dt.reset();
    this.tableComponent.filters = {};
    this.columSelect = null;
    this.formFilters?.reset();
    this.clearAllFiltersColumns.emit(true);
    this.filteredTotals = 0;
  }

  showFilters() {
    this.showFilter = this.showFilter ? false : true;
  }

  loadAllDataset() {
    this.filters = {
      ...this.filters,
      page: 1,
      page_size: 200,
      created_min: '',
      created_max: '',
    };

    this.loadDatasetEvent.emit(this.filters);
  }

  exportExcel(downloadData: any[]) {
    import('xlsx').then((xlsx) => {
      const rows = downloadData.map((item) => {
        const objAux = {};
        this.exportExcelColumns.forEach((column, i) => {
          if (item[column]) {
            Object.assign(objAux, { [column]: item[column] });
          }
        });
        return objAux;
      });
      if (rows) {
        const worksheet = xlsx.utils.json_to_sheet(rows, {
          cellDates: true,
          header: this.exportExcelColumns,
        });
        const workbook = {
          Sheets: { data: worksheet },
          SheetNames: ['data'],
        };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        this.saveAsExcelFile(
          excelBuffer,
          this.configs
            ? this.configs.fileName
              ? this.configs.fileName
              : 'kanzi-report'
            : 'kanzi-report'
        );
      }
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  exportPdf(downloadData: any[]) {
    import('jspdf').then((jsPDF) => {
      const doc = new jsPDF.default('landscape');
      autoTable(doc, {
        columns: this.exportColumns,
        body: downloadData,
        tableWidth: 'auto',
        pageBreak: 'auto',
        margin: 10,
      });
      doc.save(this.configs ? this.configs.fileName + '.pdf' : 'kanzi-report');
    });
  }

  selectFilter($event: any, col: any, overlaypanel: any) {
    this.columSelect = col;
    if ($event) {
      overlaypanel.toggle($event);
    }
  }
}
