import { Component, inject } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';
import { ProductLineWmsService } from './product-list-wms.service';
import { IProductsSelected, ProductListQuery } from './product-list.query';

@Component({
  selector: 'kanzi-apes-line-create',
  exportAs:'lineCreate',
  templateUrl: './kanzi-ui-line-create.component.html',
  styleUrls: ['./kanzi-ui-line-create.component.scss'],
  providers: [ProductListQuery],
})
export class KanziUiLineCreateComponent {
  private productListQuery = inject(ProductListQuery);
  private productLineWmsService = inject(ProductLineWmsService);
  public displayDialogSkuList = false;
  public displayDialogProducts = false;
  public productsListText: string | null = null;
  public barCodeFilter = '';
  public defaultImage = 'assets/images/backgrounds/not-image-400x300.png';
  public queryProducts = this.productListQuery.productsQuery;
  public productsSelected = this.productListQuery.productsSelected;

  public get getProductsSelected(){
    return this.productsSelected();
  }

  public onDisplayDialogProducts() {
    this.displayDialogProducts = true;
  }

  public onSaveProducts(product: IProductsSelected[] | undefined) {
    if (!product) return;
    const selected = product.filter((p) => p.isSelected);
    this.productListQuery.onSaveProductSelected(selected);
    this.displayDialogProducts = false;
  }

  public onDeleteRowData(rowData: IProductsSelected) {
    rowData.isSelected = false;
    rowData.amount = 0;
    const newSelected = this.productsSelected().filter((p) => p.isSelected);
    this.productListQuery.onSaveProductSelected(newSelected);
  }

  public saveListProducts(productsListText: string | null) {
    if (productsListText) {
      const rowsProducts = productsListText.split('\n');
      if (rowsProducts.length > 0) {
        const products: { amount: number; reference: string }[] = [];
        rowsProducts.forEach((item) => {
          const [reference, amount] = item.split(',');
          const referenceTrim = reference.replace(/\s/g, '');

          products.push({
            amount: Number(amount),
            reference: referenceTrim,
          });
        });

        const refSet = [...new Set(products.map((p) => p.reference))];
        const productsAmountTotal: { amount: number; reference: string }[] = [];
        refSet.forEach((ref) => {
          const total = products
            .filter((p) => p.reference === ref)
            .map((p) => p.amount)
            .reduce((value, total) => value + total);

          const searchRef = this.productsSelected().find(
            (p) => p.reference_id === ref
          );

          if (searchRef) {
            searchRef.amount = searchRef.amount + total;
          } else {
            productsAmountTotal.push({
              amount: total,
              reference: ref,
            });
          }
        });

        this.getProducts(productsAmountTotal).subscribe({
          next: (payload) => {
            this.productListQuery.onUpdateProductsSelected(payload);
          },
          complete: () => {
            this.onDeleteList()
          },
        });
      }
    }
  }

  public getProducts(
    productsAmountTotal: { amount: number; reference: string }[]
  ): Observable<IProductsSelected[]> {
    const services$ = productsAmountTotal.map((p) =>
      this.productLineWmsService.getProductList({
        reference_id__eq: p.reference,
      })
    );
    return forkJoin(services$).pipe(
      map((payload) => {
        return payload.map((productComplete, index) => {
          const amount = productsAmountTotal[index].amount;
          return {
            ...productComplete.results[0],
            isSelected: true,
            amount,
          } as IProductsSelected;
        });
      })
    );
  }

  public onDeleteList() {
    this.productsListText = null;
    this.displayDialogSkuList = false;
  }
}
