import { Route } from '@angular/router';

export const kanziLocationManagementRoutes: Route[] = [
  {
    path: '',
    redirectTo: '/administration/locations/warehouses',
    pathMatch: 'full',
  },
  {
    path: 'warehouses',
    loadComponent: () =>
      import('./pages/warehouse-wms-list').then(
        (m) => m.KanziWarehouseWmsListComponent
      ),
    data: {
      breadcrumb: 'Listado Bodegas',
    },
  },
  {
    path: 'warehouses-create',
    loadComponent: () =>
      import('./pages/warehouse-create').then(
        (m) => m.WarehouseCreateComponent
      ),
    data: {
      breadcrumb: 'Crear Bodega',
    },
  },
  {
    path: 'location-create',
    loadComponent: () =>
      import('./pages/location-create').then(
        (m) => m.LocationCreateComponent
      ),
    data: {
      breadcrumb: 'Crear Zona/Ubicación',
    },
  },
  {
    path: 'warehouses-create/:id',
    loadComponent: () =>
      import('./pages/warehouse-create').then(
        (m) => m.WarehouseCreateComponent
      ),
    data: {
      breadcrumb: 'Editar Bodega',
    },
  },
  {
    path: 'location-create/:id',
    loadComponent: () =>
      import('./pages/location-create').then(
        (m) => m.LocationCreateComponent
      ),
    data: {
      breadcrumb: 'Editar Zona/Ubicación',
    },
  },
  {
    path: 'positions',
    loadComponent: () =>
      import('./pages/warehouse-wms-location-list').then(
        (m) => m.KanziWarehouseWmsLocationListComponent
      ),
    data: {
      breadcrumb: 'Listado Ubicaciones',
    },
  },
];
