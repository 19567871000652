/* eslint-disable @nx/enforce-module-boundaries */
import { computed, inject, Injectable, signal } from '@angular/core';
import {
  ProductsWMSFilterModel,
  ProductWMSListModel,
  ProductWMSModel,
} from '@kanzi-apes/kanzi-models';
import {
  injectQuery,
  injectQueryClient,
} from '@tanstack/angular-query-experimental';
import { lastValueFrom } from 'rxjs';
import { ProductLineWmsService } from './product-list-wms.service';

export interface IProductsSelected extends ProductWMSModel {
  isSelected: boolean;
  amount: number;
}

@Injectable()
export class ProductListQuery {
  private queryClient = injectQueryClient();
  private productLineWmsService = inject(ProductLineWmsService);
  private filters = signal<ProductsWMSFilterModel | null>(null);
  private _productsSelected = signal<IProductsSelected[]>([]);
  public productsSelected = computed(() => this._productsSelected());

  public productsQuery = injectQuery<
    ProductWMSListModel,
    Error,
    IProductsSelected[]
  >(() => ({
    queryKey: ['wms', 'products', 'list', this.filters()],
    queryFn: () =>
      lastValueFrom(this.productLineWmsService.getProductList(this.filters())),
    select: (payload) =>
      payload.results.map((item) => ({
        ...item,
        isSelected: this.searchProductSelected(item),
        amount:
          this.productsSelected().find((p) => p.id === item.id)?.amount ?? 0,
      })),
  }));

  private searchProductSelected(payload: ProductWMSModel): boolean {
    return !!this.productsSelected().find((item) => item.id === payload.id);
  }

  public updateFilters(filters: ProductsWMSFilterModel | null) {
    this.filters.set(filters);
  }

  public onSaveProductSelected(products: IProductsSelected[]) {
    this._productsSelected.set(products);
  }

  public onUpdateProductsSelected(products: IProductsSelected[]){
    products.forEach((product)=>{
      const findProduct = this.productsQuery.data()?.find((p)=>p.reference_id===product.reference_id);
      if(findProduct){
        findProduct.amount =findProduct.amount + product.amount;
        findProduct.isSelected = true;
      }
    })

    const selected = this.productsQuery.data()?.filter(p=>p.isSelected);
    if(selected) this.onSaveProductSelected(selected);
  }

  public onInvalidQuery() {
    this.queryClient.invalidateQueries({
      queryKey: ['wms', 'products', 'list'],
    });
  }
}
