import { ListResults } from '../data';

export interface ProductsWMSFilterModel {
  last_evaluated_key_id?: string | null | undefined;
  name__contains?: string | null | undefined;
  barcode__contains?: string | null | undefined;
  search?: string | null | undefined;
  reference_id__eq?:string | number;
  barcode__eq?:string | number | null;
  external_id__eq?:string | number | null;
  id__eq?:string | number | null;
}

export interface ProductTypeWMSFilterModel {
  last_evaluated_key_id?: string | null | undefined;
}

export interface ProductGroupWMSFilterModel {
  last_evaluated_key_id?: string | null | undefined;
  type_id__eq?:string | number | null;
  parent_id__eq?:string | number | null;
}

export interface ProductTypeWMSModel {
  properties: any;
  status: string;
  created: string;
  name: string;
  modified_unix_epoch: number;
  created_unix_epoch: number;
  modified: string;
  external_id: string;
  created_by_username: string;
  id: string;
  modified_by_username: string;
  position: number;
  parent_id: any;
}

export interface ProductGroupWMSModel {
  status: string;
  created: string;
  name: string;
  modified_unix_epoch: number;
  created_unix_epoch: number;
  modified: string;
  external_id: string;
  created_by_username: string;
  id: string;
  modified_by_username: string;
  parent_id: any;
  type_id: string;
  location_information: string;
}

export interface ProductPropertiesWMS {
  height_measurement_unit: string;
  large: string;
  brand_names: string[];
  is_on_demand: string;
  weight: string;
  weight_measurement_unit: string;
  volume: number;
  volume_measurement_unit: string;
  width: string;
  supplier_name: string;
  width_measurement_unit: string;
  height: string;
  large_measurement_unit: string;
}
export interface ProductGroupData {
  group_parent_id: string | null;
  group_type: string;
  group_external_id: string;
  group_name: string;
}
export interface ProductWMSModel {
  purchase_coin: string;
  purchase_price: number;
  properties: ProductPropertiesWMS;
  status: string;
  classification_name: string;
  product_id: string;
  name: string;
  recept_type: string;
  classification_id: string;
  presentation: string;
  sale_coin: string;
  pk_instance: string;
  external_id: string;
  created_by_username: string;
  id: string;
  reception_type: string;
  modified_by_username: string;
  reference_id: string;
  modified: string;
  barcode: string;
  properties_list: any[];
  substitute_external_ids: any[];
  created: string;
  barcode_type: string;
  measure_unit: string;
  display_name: string;
  substitute_ids: any[];
  groups: ProductGroupData[];
  sale_price: number;
  modified_unix_epoch: number;
  created_unix_epoch: number;
  image_files: any[];
  barcode_history: string[];
  location_information: string;
  type: any;
}

export type ProductTypeWMSListModel = ListResults<ProductTypeWMSModel>;

export type ProductGroupWMSListModel = ListResults<ProductGroupWMSModel>;

export type ProductWMSListModel = ListResults<ProductWMSModel>;
