
  import { KanziWMSEnvironmentModel } from '@kanzi-apes/kanzi-models';
  export const environment: KanziWMSEnvironmentModel = {
    environment: 'development',
    template_purchase_orders: '',
    template_dispatch_orders: '',
    template_return_orders: '',
    template_transfer_orders: '',
    template_products: '',
    template_locations: '',
    production: false,
    cognitoConfig: {
      cognitoUserPoolId: 'us-east-1_9ImT0T9eU',
      cognitoAppClientId: '1cb5ic28qttv5a0jtevu01ufff',
    },
    apiRestModules: {
      kongWMSAPI: {
        locations: {
          apiURL: 'https://api-apes-wms-rfid-dev.technoapes.io',
          apiPath: 'locations-v1',
        },
        userConfigs: {
          apiURL: 'https://api-apes-wms-rfid-dev.technoapes.io',
          apiPath: 'user-config',
        },
        thirdParties: {
          apiURL: 'https://api-2-apes-wms-rfid-dev.technoapes.io',
          apiPath: 'third',
        },
        products: {
          apiURL: 'https://api-apes-wms-rfid-dev.technoapes.io',
          apiPath: 'products',
        },
        users: {
          apiURL: 'https://api-2-apes-wms-rfid-dev.technoapes.io',
          apiPath: 'users',
        },
        orders: {
          apiURL: 'https://api-apes-wms-rfid-dev.technoapes.io',
          apiPath: 'dispatch-orders',
        },
        enlistmentOrders: {
          apiURL: 'https://api-apes-wms-rfid-dev.technoapes.io',
          apiPath: 'enlistment-orders',
        },
        deliveryOrders: {
          apiURL: 'https://api-apes-wms-rfid-dev.technoapes.io',
          apiPath: 'delivery-orders',
        },
        purchaseOrders: {
          apiURL: '',
          apiPath: 'purchase-order-v1',
        },
        purchaseOrderLines: {
          apiURL: '',
          apiPath: 'purchase-orders',
        },        
        receiptsOrders: {
          apiURL: '',
          apiPath: 'order-receipts',
        },
        returnsOrders: {
          apiURL: '',
          apiPath: 'return-order',    
        },    
        locationTypes: {
          apiURL: '',
          apiPath: 'location',
        },
        dataLoader: {
          apiURL: '',
          apiPath: 'master-files',
        },
        user_roles: {
          apiURL: '',
          apiPath: 'user-roles',
        },
        user_creation: {
          apiURL: '',
          apiPath: 'users-v1',
        },
        userConfigUpdate: {
          apiURL: '',
          apiPath: 'user-configs',
      }
    }
  }
};   