import { MenuItem } from 'primeng/api';
/**
 * Array objects for main-sibedar menu
 * @type {MenuItem[]} Array for objects compatible with menu primeng
 *
 */
export const ItemsMenu: MenuItem[] = [
  {
    label: 'PANELES',
    icon: 'pi pi-fw pi-chart-line',
    visible: true,
    routerLink: 'dashboards',
  },
  {
    automationId: 'ADM_000',
    label: 'ADMINISTRACIÓN',
    icon: 'pi pi-fw pi-cog',
    visible: true,
    items: [
      {
        automationId: 'ADM_000',
        label: 'Gestión de bodegas',
        icon: 'pi pi-fw pi-building',
        routerLink: 'administration/locations/warehouses',
      },
      {
        automationId: 'ADM_000',
        label: 'Gestión de ubicaciones',
        icon: 'pi pi-fw pi-inbox',
        routerLink: 'administration/locations/positions',
      },
      {
        automationId: 'ADM_000',
        label: 'Gestión de usuarios',
        icon: 'pi pi-fw pi-user-edit',
        routerLink: 'administration/users/list',
      },
    ],
  },
  {
    automationId: 'ADM_000',
    label: 'OPERACIONES',
    icon: 'pi pi-fw pi-briefcase',
    visible: true,
    items: [
      {
        automationId: 'CT_000',
        label: 'TERCEROS',
        icon: 'pi pi-fw pi-users',
        items: [
          {
            automationId: 'CT_001',
            label: 'Crear Nuevo',
            icon: 'pi pi-fw pi-user-plus',
            routerLink: 'third-parties/create',
          },
          {
            automationId: 'CT_002',
            label: 'Listado',
            icon: 'pi pi-fw pi-list',
            routerLink: 'third-parties/list',
          },
        ],
      },
      {
        automationId: 'PR_000',
        label: 'PRODUCTOS',
        icon: 'pi pi-fw pi-tags',
        items: [
          {
            automationId: 'PR_002',
            label: 'Listado de productos',
            icon: 'pi pi-fw pi-list',
            routerLink: 'products/list',
          },
          {
            automationId: 'PR_003',
            label: 'Stock de productos',
            icon: 'pi pi-fw pi-table',
            routerLink: '',
          },
        ],
      },
      {
        automationId: 'PR_000',
        label: 'PLANEACIÓN',
        icon: 'pi pi-fw pi-list',
        items: [
          {
            automationId: 'PR_002',
            label: 'Configuración de usuarios',
            icon: 'pi pi-fw pi-users',
            routerLink: '',
          },
          {
            automationId: 'PR_002',
            label: 'Configuración de alistamientos',
            icon: 'pi pi-fw pi-shopping-cart',
            routerLink: '',
          },
          {
            automationId: 'PR_002',
            label: 'Configuración de despachos',
            icon: 'pi pi-fw pi-truck',
            routerLink: '',
          },
        ],
      },
      {
        automationId: 'PR_000',
        label: 'GESTIÓN DE PEDIDOS',
        icon: 'pi pi-fw pi-file',
        items: [
          {
            automationId: 'PR_002',
            label: 'Estados de pedidos',
            icon: 'pi pi-fw pi-verified',
            routerLink: 'operations/orders-list',
          },
          {
            automationId: 'PR_002',
            label: 'Tareas de alistamiento',
            icon: 'pi pi-fw pi-file-edit',
            routerLink: 'operations/enlistment-list',
          },
          {
            automationId: 'PR_002',
            label: 'Tareas de despacho',
            icon: 'pi pi-fw pi-file-export',
            routerLink: 'operations/dispatch-list',
          },
        ],
      },
      {
        automationId: 'PR_000',
        label: 'ORDENES DE RECEPCIÓN',
        icon: 'pi pi-fw pi-truck',
        items: [
          {
            automationId: 'PR_002',
            label: 'Órdenes de compra',
            icon: 'pi pi-fw pi-cart-plus',
            routerLink: 'reception/purchase-list',
          },
          {
            automationId: 'PR_002',
            label: 'Órdenes de Traslado',
            icon: 'pi pi-fw pi-truck',
            routerLink: 'reception/transfer-list',
          },
          {
            automationId: 'PR_002',
            label: 'Órdenes de devolución',
            icon: 'pi pi-fw pi-refresh',
            routerLink: 'reception/return-list',
          },
        ],
      },
      {
        automationId: 'PR_000',
        label: 'CONTEO DE INVENTARIO',
        icon: 'pi pi-fw pi-calculator',
        items: [
          {
            automationId: 'PR_002',
            label: 'Programación de conteos',
            icon: 'pi pi-fw pi-calendar',
            routerLink: '',
          },
          {
            automationId: 'PR_002',
            label: 'Listado de conteos',
            icon: 'pi pi-fw pi-list',
            routerLink: '',
          },
        ],
      },
    ],
  },
];
