import {
  Component,
  effect,
  inject,
  OnDestroy,
  OnInit,
  Renderer2,
  runInInjectionContext,
  signal,
  untracked,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, Subscription } from 'rxjs';
import { KanziPrimeUiModule, MenuItem } from '@kanzi-apes/kanzi-prime-ui';
import {
  KanziUiTopBarComponent,
  MainMenuService,
  KanziUiMainSidebarComponent,
  LayoutService,
} from '@kanzi-apes/kanzi-ui';
import { KanziUiModule } from '@kanzi-apes/kanzi-ui';
import { ItemsMenu } from '../configs/main-menu';
import kanziVersion from 'kanziVersion.json';
import * as Auth from '@kanzi-apes/kanzi-authenticator';
import { LocationsQueries } from '@kanzi-apes/kanzi-locations';
import * as Locations from '@kanzi-apes/kanzi-locations';
import { AngularQueryDevtools } from '@tanstack/angular-query-devtools-experimental';
import { WarehouseModel } from '@kanzi-apes/kanzi-models';
import { KanziAlertsModule } from '@kanzi-apes/kanzi-alerts';
import { KanziCustomerModule } from '@kanzi-apes/kanzi-customer';

@Component({
  selector: 'kanzi-apes-layout',
  standalone: true,
  imports: [
    CommonModule,
    KanziPrimeUiModule,
    KanziUiModule,
    AngularQueryDevtools,
    KanziAlertsModule,
    KanziCustomerModule
  ],
  providers: [],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent implements OnInit, OnDestroy {
  layoutService: LayoutService;
  mainMenuService: MainMenuService = inject(MainMenuService);
  router: Router = inject(Router);
  storeAuth: Store<Auth.AuthState> = inject(Store);
  storeLocations: Store<Locations.LocationsState> = inject(Store);
  authService = inject(Auth.AuthService);
  locationsQueries = inject(LocationsQueries);
  warehouseListQuery = this.locationsQueries.warehouseRootListQuery;
  progressBar = signal(true);
  itemsMenu = signal<MenuItem[]>([]);
  kanziAppVersion = signal('');
  userProfile = this.storeAuth.selectSignal(Auth.getAuthUser);
  userConfigs = this.storeAuth.selectSignal(Auth.getAuthUserWMSConfig);
  userWarehouseRootSelected = this.storeLocations.selectSignal(
    Locations.getWarehouseRootSelected
  );

  overlayMenuOpenSubscription: Subscription;
  topbarMenuOpenSubscription: Subscription;
  menuProfileOpenSubscription: Subscription;
  menuOutsideClickListener: any;
  menuScrollListener: any;
  topbarMenuOutsideClickListener: any;
  menuProfileOutsideClickListener: any;

  @ViewChild(KanziUiTopBarComponent) appTopbar!: KanziUiTopBarComponent;
  @ViewChild(KanziUiMainSidebarComponent)
  appSidebar!: KanziUiMainSidebarComponent;

  private layoutEffects = effect(() => {
    if (this.userConfigs() && this.warehouseListQuery.data()) {
      const warehouseRootId = this.userConfigs();
      const warehouseRoots = this.warehouseListQuery.data();
      let warehouseDefault =
        warehouseRoots?.results.find(
          (warehouseRoot) =>
            warehouseRoot.id === warehouseRootId?.default_location_id
        ) || null;
      if (warehouseDefault) {
        untracked(() => {
          if (warehouseRootId) {
            this.storeLocations.dispatch(
              Locations.warehouseRootIdSelected({
                warehouseRootId: warehouseRootId.default_location_id,
              })
            );
          }
          this.storeLocations.dispatch(
            Locations.selectWarehouseRoot({ warehouseRoot: warehouseDefault })
          );
        });
      }
    }
  });

  constructor(public renderer: Renderer2) {
    this.layoutService = inject(LayoutService);
    this.kanziAppVersion.set(kanziVersion.version);
    this.hideMenuProfile();

    this.overlayMenuOpenSubscription =
      this.layoutService.overlayOpen$.subscribe(() => {
        this.hideTopbarMenu();

        if (!this.menuOutsideClickListener) {
          this.menuOutsideClickListener = this.renderer.listen(
            'document',
            'click',
            (event) => {
              const isOutsideClicked = !(
                this.appSidebar.el.nativeElement.isSameNode(event.target) ||
                this.appSidebar.el.nativeElement.contains(event.target) ||
                this.appTopbar.menuButton.nativeElement.isSameNode(
                  event.target
                ) ||
                this.appTopbar.menuButton.nativeElement.contains(event.target)
              );
              if (isOutsideClicked) {
                this.hideMenu();
              }
            }
          );
        }

        if (
          (this.layoutService.isHorizontal() ||
            this.layoutService.isSlim() ||
            this.layoutService.isSlimPlus()) &&
          !this.menuScrollListener
        ) {
          this.menuScrollListener = this.renderer.listen(
            this.appSidebar.menuContainer.nativeElement,
            'scroll',
            (event) => {
              if (this.layoutService.isDesktop()) {
                this.hideMenu();
              }
            }
          );
        }

        if (this.layoutService.state.staticMenuMobileActive) {
          this.blockBodyScroll();
        }
      });

    this.topbarMenuOpenSubscription =
      this.layoutService.topbarMenuOpen$.subscribe(() => {
        if (!this.topbarMenuOutsideClickListener) {
          this.topbarMenuOutsideClickListener = this.renderer.listen(
            'document',
            'click',
            (event) => {
              const isOutsideClicked = !(
                this.appTopbar.el.nativeElement.isSameNode(event.target) ||
                this.appTopbar.el.nativeElement.contains(event.target) ||
                this.appTopbar.mobileMenuButton.nativeElement.isSameNode(
                  event.target
                ) ||
                this.appTopbar.mobileMenuButton.nativeElement.contains(
                  event.target
                )
              );
              if (isOutsideClicked) {
                this.hideTopbarMenu();
              }
            }
          );
        }

        if (this.layoutService.state.staticMenuMobileActive) {
          this.blockBodyScroll();
        }
      });

    this.menuProfileOpenSubscription =
      this.layoutService.menuProfileOpen$.subscribe(() => {
        this.hideMenu();

        if (!this.menuProfileOutsideClickListener) {
          this.menuProfileOutsideClickListener = this.renderer.listen(
            'document',
            'click',
            (event) => {
              const isOutsideClicked = !(
                this.appSidebar.menuProfile.el.nativeElement.isSameNode(
                  event.target
                ) ||
                this.appSidebar.menuProfile.el.nativeElement.contains(
                  event.target
                )
              );
              if (isOutsideClicked) {
                this.hideMenuProfile();
              }
            }
          );
        }
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.hideMenu();
        this.hideTopbarMenu();
        this.hideMenuProfile();
      });
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          '(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)',
          'gi'
        ),
        ' '
      );
    }
  }

  hideMenu() {
    this.layoutService.state.overlayMenuActive = false;
    this.layoutService.state.staticMenuMobileActive = false;
    this.layoutService.state.menuHoverActive = false;
    this.mainMenuService.reset();

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
      this.menuOutsideClickListener = null;
    }

    if (this.menuScrollListener) {
      this.menuScrollListener();
      this.menuScrollListener = null;
    }
    this.unblockBodyScroll();
  }

  hideTopbarMenu() {
    this.layoutService.state.topbarMenuActive = false;

    if (this.topbarMenuOutsideClickListener) {
      this.topbarMenuOutsideClickListener();
      this.topbarMenuOutsideClickListener = null;
    }
  }

  hideMenuProfile() {
    this.layoutService.state.menuProfileActive = false;

    if (this.menuProfileOutsideClickListener) {
      this.menuProfileOutsideClickListener();
      this.menuProfileOutsideClickListener = null;
    }
  }

  get containerClass() {
    let styleClass: { [key: string]: any } = {
      'layout-overlay': this.layoutService.config().menuMode === 'overlay',
      'layout-static': this.layoutService.config().menuMode === 'static',
      'layout-slim': this.layoutService.config().menuMode === 'slim',
      'layout-slim-plus': this.layoutService.config().menuMode === 'slim-plus',
      'layout-horizontal':
        this.layoutService.config().menuMode === 'horizontal',
      'layout-reveal': this.layoutService.config().menuMode === 'reveal',
      'layout-drawer': this.layoutService.config().menuMode === 'drawer',
      'p-input-filled': this.layoutService.config().inputStyle === 'filled',
      'p-ripple-disabled': !this.layoutService.config().ripple,
      'layout-static-inactive':
        this.layoutService.state.staticMenuDesktopInactive &&
        this.layoutService.config().menuMode === 'static',
      'layout-overlay-active': this.layoutService.state.overlayMenuActive,
      'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
      'layout-topbar-menu-active': this.layoutService.state.topbarMenuActive,
      'layout-menu-profile-active': this.layoutService.state.menuProfileActive,
      'layout-sidebar-active': this.layoutService.state.sidebarActive,
      'layout-sidebar-anchored': this.layoutService.state.anchored,
    };
    styleClass['layout-topbar-' + this.layoutService.config().topbarTheme] =
      true;
    styleClass['layout-menu-' + this.layoutService.config().menuTheme] = true;
    styleClass[
      'layout-menu-profile-' + this.layoutService.config().menuProfilePosition
    ] = true;
    return styleClass;
  }

  logoutSession() {
    this.storeAuth.dispatch(Auth.logout());
  }

  onSelectWarehouse(warehouseRootSelected: WarehouseModel) {
    this.storeLocations.dispatch(
      Locations.selectWarehouseRoot({ warehouseRoot: warehouseRootSelected })
    );
  }

  ngOnInit() {
    this.progressBar.set(false);
    this.itemsMenu.set(ItemsMenu);
    this.authService.cognitoUserObject?.getUserData((err, userData) => {
      if (err) {
        console.log(err);
      } else {
        if (userData) {
          this.storeAuth.dispatch(Auth.userProfileSuccess({ user: userData }));
          this.storeAuth.dispatch(
            Auth.loadUserConfigsWMS({
              userConfigFilters: {
                merge_rol_actions: true,
                username__eq: userData.Username,
              },
            })
          );
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.overlayMenuOpenSubscription) {
      this.overlayMenuOpenSubscription.unsubscribe();
    }

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
    }

    this.layoutEffects.destroy();
  }
}
