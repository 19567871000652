<div
  class="layout-topbar"
  [ngClass]="{
    'border-bottom-none': layoutService.config().topbarTheme !== 'light'
  }"
>
  <div class="layout-topbar-start">
    <a class="layout-topbar-logo" routerLink="/">
      <img
        class="horizontal-logo"
        [routerLink]="['/']"
        [src]="'assets/images/logos/logo-apes-' + logo + '.svg'"
        alt="Kanzi-Logo"
      />
      <span class="text-2xl font-bold text-color ml-2">Kanzi</span>
    </a>
    <a
      #menuButton
      class="layout-menu-button"
      (click)="onMenuButtonClick()"
      pRipple
    >
      <i class="pi pi-angle-right"></i>
    </a>
    <a
      #mobileMenuButton
      class="layout-topbar-mobile-button"
      (click)="onMobileTopbarMenuButtonClick()"
      pRipple
    >
      <i class="pi pi-bars text-4xl"></i>
    </a>
  </div>
  <div class="layout-topbar-end">
    <div class="layout-topbar-actions-end">
      <ul class="layout-topbar-items">
        <li class="layout-topbar-search w-20rem">
          <kanzi-warehouse-root-selector
            placeholderSelector="{{ 'Seleccionar Bodega' | translate }}"
            class="w-full"
            [warehouseList]="warehouseRootList()"
            [warehouseRootsSelected]="warehouseRootSelected()"
            (selectWarehouseRoot)="onSelectWarehouse($event)"
          ></kanzi-warehouse-root-selector>
        </li>
        <li >
          <a routerLink="/data-loader"  >
            <i title="{{ 'Actividad de carga' | translate }}" class="pi pi-upload"></i>
          </a >        
        </li>
        <li>
          <a
            pStyleClass="@next"
            enterFromClass="ng-hidden"
            enterActiveClass="px-scalein"
            leaveToClass="ng-hidden"
            leaveActiveClass="px-fadeout"
            [hideOnOutsideClick]="true"
            pRipple
          >
            <i class="pi pi-language"></i>
          </a>
          <div class="ng-hidden">
            <ul class="list-none p-0 m-0">
              <li *ngFor="let lang of languajes">
                <a
                  class="py-2 px-3 flex gap-2 cursor-pointer text-color hover:text-primary"
                >
                  <img
                    src="assets/demo/images/flag/flag_placeholder.png"
                    [class]="'flag flag-' + lang.code"
                    class="w-2rem mr-2"
                  />
                  <span>{{ lang.label }}</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
