<div class="progressContainer" *ngIf="progressBar()">
  <p-progressSpinner *ngIf="progressBar()"></p-progressSpinner>
</div>
<div class="layout-container" [ngClass]="containerClass">
  <kanzi-ui-top-bar
    [warehouseRootList]="warehouseListQuery.data()"
    [warehouseRootSelected]="userWarehouseRootSelected()"
    (selectWarehouseRoot)="onSelectWarehouse($event)"
  ></kanzi-ui-top-bar>
  <kanzi-ui-main-sidebar
    [mainMenuItems]="itemsMenu()"
    [userProfile]="userProfile()"
    [userConfigsWMS]="userConfigs()"
    (onLogout)="logoutSession()"
  ></kanzi-ui-main-sidebar>
  <div class="layout-content-wrapper">
    <kanzi-ui-breadcrumb></kanzi-ui-breadcrumb>
    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>
    <kanzi-ui-footer [versionName]="kanziAppVersion()"></kanzi-ui-footer>
  </div>
</div>
<app-alert-voice></app-alert-voice>
<app-alert></app-alert>
<angular-query-devtools />
