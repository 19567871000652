import { FiltersModel } from '../filters';
import { DataModel, DataListModel, ListResults } from '../data';

export interface ContactCreateModel {
  customer_id?: number;
  name: string;
  external_id: string;
  last_name?: string;
  type_contact: string;
  type_identification: string;
  identification: string;
  properties?: ContactPropertiesModel;
  email?: string;
  address_type?: string;
  params?: any;
  id?: number;
  discount_display?:boolean;
}
export interface ContactModel extends DataModel {
  name?: string;
  external_id: string;
  identification: number;
  type_identification: string;
  type_contact: string;
  customer_id?: number;
  properties?: ContactPropertiesModel;
  email?: string;
  first_name?: string;
  last_name?: string;
  address_type?: string;
  params?: any;
  discount_display:boolean;
}

export interface ContactPropertiesModel {
  phone?: string;
  last_order_id?: string;
  addresses?: any[];
}

export interface ContactListModel extends DataListModel {
  results: ContactModel[];
}

export interface ContactFilterModel extends FiltersModel {
  type_contact?: string;
}

export interface ServicesContactsListModel extends DataListModel {
  results: ServicesContacts[];
}

export interface ServicesContacts {
  id?: number;
  contact_id: number;
  is_active: boolean;
  external_id: string;
  customer?: number;
  name: string | null;
  discount_rate: number;
}

export interface ServiceFilters extends FiltersModel {
  contact_id: string;
}

export interface ThirdPartyWMSFilterModel {
  last_evaluated_key_id?: string | null | undefined;
  name__contains?: string | null | undefined;
}

export interface ThirdPartyModel {
  document_type: string;
  third_type: string;
  status: string;
  created: string;
  document_number: string | null;
  name: string;
  modified_unix_epoch: number;
  created_unix_epoch: number;
  modified: string;
  external_id: string;
  created_by_username: string;
  modified_by_username: string;
  id: string;
}

export type ThirdPartiesListModel = ListResults<ThirdPartyModel>;
