import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as LayoutActions from './layout.actions';
import { LayoutEntity } from './layout.models';

export const LAYOUT_FEATURE_KEY = 'layout';

export interface LayoutState extends EntityState<LayoutEntity> {
  showMenuMain: string;
  showRightMenu: string;
  showProgressbar: boolean;
  customerParent: boolean;
}

export interface LayoutPartialState {
  readonly [LAYOUT_FEATURE_KEY]: LayoutState;
}

export const layoutAdapter: EntityAdapter<LayoutEntity> =
  createEntityAdapter<LayoutEntity>();

export const initialLayoutState: LayoutState = layoutAdapter.getInitialState({
  showMenuMain: window.innerWidth < 600 ? 'closed' : 'open',
  showRightMenu: 'close',
  showProgressbar: false,
  customerParent: false,
});

const reducer = createReducer(
  initialLayoutState,
  on(LayoutActions.initLayout, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(LayoutActions.actionMainMenu, (state, { action }) => ({
    ...state,
    showMenuMain: action,
  })),
  on(LayoutActions.actionRightMenu, (state, { action }) => ({
    ...state,
    showRightMenu: action,
  })),
  on(LayoutActions.openMenuMain, (state) => ({ ...state })),
  on(LayoutActions.closeMenuMain, (state) => ({ ...state })),
  on(LayoutActions.showProgressBar, (state) => ({
    ...state,
    showProgressbar: true,
  })),
  on(LayoutActions.hideProgressBar, (state) => ({
    ...state,
    showProgressbar: false,
  })),
  on(LayoutActions.activateCustomerParent, (state) => ({
    ...state,
    customerParent: true,
  })),
  on(LayoutActions.deactivateCustomerParent, (state) => ({
    ...state,
    showProgressbar: false,
  }))
);

export function layoutReducer(state: LayoutState | undefined, action: Action) {
  return reducer(state, action);
}
