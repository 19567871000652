import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import {
  provideRouter,
  withDebugTracing,
  withRouterConfig,
} from '@angular/router';
import { HttpClientModule, provideHttpClient } from '@angular/common/http';
import { appRoutes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { KanziUtilsModule } from '@kanzi-apes/kanzi-utils';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import * as AuthStore from '@kanzi-apes/kanzi-authenticator';
import { EffectsModule } from '@ngrx/effects';
import { reducers, metaReducers } from './+state/reducers';
import * as LayoutReducer from './+state/layout/layout.reducer';
/* import {
  FullRouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store'; */
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  provideAngularQuery,
  QueryClient,
} from '@tanstack/angular-query-experimental';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      withDebugTracing(),
      withRouterConfig({ onSameUrlNavigation: 'reload' })
    ),
    provideAnimations(),
    provideHttpClient(),
    importProvidersFrom(KanziUtilsModule.forRoot({ wmsEnv: environment })),
    importProvidersFrom(
      StoreModule.forRoot(reducers, {
        metaReducers,
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      })
    ),
    importProvidersFrom(
      StoreDevtoolsModule.instrument({
        name: 'Kanzi WMS DevTools',
        logOnly: environment.production,
        maxAge: 30,
        connectInZone: true,
      })
    ),
    importProvidersFrom(EffectsModule.forRoot([])),
    importProvidersFrom(
      StoreModule.forFeature(
        LayoutReducer.LAYOUT_FEATURE_KEY,
        LayoutReducer.layoutReducer
      )
    ),
    importProvidersFrom(
      StoreModule.forFeature(AuthStore.AUTH_FEATURE_KEY, AuthStore.authReducer)
    ),
    importProvidersFrom(EffectsModule.forFeature(AuthStore.AuthEffects)),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      })
    ),
    provideAngularQuery(new QueryClient()),
  ],
};
