<p-table
  [value]="_linesList"
  [rows]="10"
  [paginator]="true"
  [totalRecords]="_linesList.length"
  [responsive]="true"
  [styleClass]="'kanzi-ui'"
  [loading]="loading"
  [resizableColumns]="true"
  editMode="row"
  dataKey="sku_id"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th>{{ 'Nombre' | translate }}</th>
      <th>{{ 'SKU' | translate }}</th>
      <th>{{ 'External ID' | translate }}</th>
      <th style="width: 15em">{{ 'Cantidad' | translate }}</th>
      <th *ngIf="unitPrice">{{ 'Precio Unitario' | translate }}</th>
      <th *ngIf="positioning" style="width: 15em">
        {{ 'Posicionamiento' | translate }}
      </th>
      <th style="width: 8em"></th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowData
    let-editing="editing"
    let-ri="rowIndex"
  >
    <tr [pEditableRow]="rowData" id="rowsDatas__tr_overflow">
      <td>
        {{ rowData.sku_display_name }}
      </td>
      <td>{{ rowData.sku_id }}</td>
      <td>{{ rowData.sku_external_id }}</td>
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              type="number"
              pInputText
              [(ngModel)]="rowData.amount"
              placeholder="0.0"
              [ngModelOptions]="{ standalone: true }"
              [style]="{ width: '8rem' }"
              [min]="1"
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.amount }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td *ngIf="unitPrice">
        {{
          rowData.properties['Precio_Unitario']
            ? rowData.properties['Precio_Unitario']
            : ''
        }}
      </td>
      <!-- inicio Posicionamiento -->
      <td *ngIf="positioning">
        <!-- Modificar el p-dropdown -->
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown
              [options]="rowData.locationsSuggested"
              [(ngModel)]="rowData.locationOption"
              optionLabel="display_name"
              [filter]="true"
              [showClear]="true"
              dataKey="display_name"
              appendTo="body"
              [style]="{ width: '13rem' }"
              (onChange)="onSelectSkuLocation($event, ri)"
            >
            </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.locationOption?.display_name }}
          </ng-template>
        </p-cellEditor>
      </td>
      <!-- fin Posicionamiento -->

      <td style="text-align: center">
        <button
          *ngIf="!editing"
          pButton
          type="button"
          pInitEditableRow
          icon="pi pi-pencil"
          class="p-button-info mr-2 p-button-text"
          (click)="onRowEditInit(rowData)"
        ></button>
        <button
          *ngIf="!editing"
          pButton
          type="button"
          icon="pi pi-trash"
          class="p-button-danger mr-2 p-button-text"
          (click)="onRowDelete(ri)"
        ></button>
        <button
          *ngIf="editing"
          pButton
          type="button"
          pSaveEditableRow
          icon="pi pi-check"
          class="p-button-success mr-2 p-button-text"
          (click)="onRowEditSave(rowData)"
        ></button>
        <button
          *ngIf="editing"
          pButton
          type="button"
          pCancelEditableRow
          icon="pi pi-times"
          class="p-button-danger p-button-text"
          (click)="onRowEditCancel(rowData, ri)"
        ></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="6">
        {{ 'Registros no encontrados.' | translate }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary" *ngIf="canAddLines">
    <div class="lex justify-content-start">
      <button
        icon="pi pi-plus"
        type="button"
        class="p-button-help mr-3"
        pButton
        pRipple
        (click)="addSkuList()"
      ></button>
      <button
        type="button"
        icon="pi pi-file"
        class="mr-3"
        (click)="showDialogToAdd()"
        pButton
        pRipple
        label="{{ 'Subir lista de Skus' | translate }}"
      ></button>
      <button
        type="button"
        icon="fas fa-qrcode"
        class="p-button-secondary mr-3"
        (click)="showDialogToScanCode()"
        pButton
        pRipple
        label="{{ 'Escanear Código' | translate }}"
      ></button>
    </div>
  </ng-template>
</p-table>
<p-toast></p-toast>

<!-- Dialog to Scan Code -->
<p-dialog
  header="{{ 'Escanear Código' | translate }}"
  [modal]="true"
  [(visible)]="displayDialogScan"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '30vw' }"
  [baseZIndex]="10000"
>
  <div class="field mt-3">
    <input
      pInputText
      #barcode
      id="float-input"
      class="w-full"
      (blur)="
        onSkuBarcodeReading(barcode.value); barcode.value = ''; barcode.focus()
      "
    />
  </div>
  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      pRipple
      class="p-button-text p-button-secondary"
      icon="pi pi-times"
      (click)="displayDialogScan = false"
      label="{{ 'Cerrar' | translate }}"
    ></button>
  </ng-template>
</p-dialog>

<!-- sku list -->
<p-dialog
  header="{{
    byExternalId
      ? 'Ingresar la lista de External id y Cantidad'
      : ('Ingresar la lista de Sku y Cantidad' | translate)
  }}"
  [(visible)]="displayDialog"
  [responsive]="true"
  showEffect="fade"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '30vw' }"
  [modal]="true"
  [draggable]="false"
>
  <div class="field-radiobutton" *ngIf="showSelectTypeList">
    <label for="external_id" class="mr-2">{{
      'Cargar por External id:' | translate
    }}</label>
    <p-inputSwitch class="mt-2" [(ngModel)]="byExternalId"></p-inputSwitch>
  </div>
  <div class="field">
    <label for="skulist">{{
      byExternalId
        ? 'Ingresa en el formato por linea: External id,cantidad'
        : ('Ingresa en el formato por linea: sku,cantidad' | translate)
    }}</label>
    <textarea
      pInputTextarea
      name="skulist"
      #skulist="ngModel"
      placeholder="{{
        byExternalId ? 'External id, Cantidad' : ('Sku, Cantidad' | translate)
      }}"
      class="w-full"
      [rows]="10"
      [(ngModel)]="skuListText"
      [ngModelOptions]="{ standalone: true }"
    ></textarea>
  </div>
  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      pRipple
      class="p-button-text p-button-danger mr-2"
      icon="pi pi-times"
      (click)="delete()"
      label="{{ 'Borrar' | translate }}"
    ></button>
    <button
      type="button"
      pButton
      pRipple
      class="p-button-text p-button-success"
      icon="pi pi-save"
      (click)="saveListSkus()"
      label="{{ 'Guardar' | translate }}"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="displaySider"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '55vw', 'min-height': '50vh' }"
  [modal]="true"
  [draggable]="false"
  [maximizable]="true"
  header="{{ 'Listado de Sku' | translate }}"
  (onHide)="closeDialog()"
>
  <div class="list-container">
    <p-dataView
      [value]="skusList ? skusList : []"
      [styleClass]="'pendingTask'"
      [loading]="pendingList()"
      emptyMessage="No se encontraron resultados"
    >
      <ng-template pTemplate="header">
        <div class="p-input-icon-left w-full">
          <i class="pi pi-search"></i>
          <input
            id="skuSearch"
            name="skuSearch"
            type="text"
            pInputText
            placeholder="Buscar..."
            class="w-full"
            [(ngModel)]="filterSkusList"
          />
        </div>
      </ng-template>

      <ng-template let-skus pTemplate="list">
        @for (sku of skus; track sku.id){ @if(pendingList()){
        <div class="product-item">
          <p-skeleton size="1rem" styleClass="mr-2"></p-skeleton>
          <div class="image-container">
            <p-skeleton size="5rem"></p-skeleton>
          </div>
          <div class="product-list-detail">
            <p-skeleton
              width="8rem"
              height="2rem"
              styleClass="mb-2 ml-2"
            ></p-skeleton>
            <p-skeleton
              width="15rem"
              height="1rem"
              styleClass="product-category ml-2"
            ></p-skeleton>
          </div>
          <div class="product-list-action">
            <div class="field">
              <p-skeleton
                width="13rem"
                height="2.5rem"
                styleClass="mb-2 mr-4"
              ></p-skeleton>
            </div>
          </div>
        </div>
        }@else {

        <div class="product-item">
          <p-checkbox
            name="skus"
            inputId="sku"
            class="mr-3"
            [binary]="true"
            [(ngModel)]="sku.selected"
            [value]="sku.selected"
            (onChange)="selectItem(sku)"
          ></p-checkbox>
          <div class="image-container">
            <img
              [src]="sku?.photo ? sku?.photo : defaultImage"
              [alt]="sku.name"
              class="product-image"
            />
          </div>
          <div class="product-list-detail">
            <h5 class="mb-2 text-indigo-500">{{ sku.display_name }}</h5>
            <i class="pi pi-tag product-category-icon"></i>
            <span class="product-category mr-2"
              ><strong>SKU: </strong>{{ sku.id }}</span
            >
            <span class="product-category"
              ><strong>External ID:</strong>{{ sku.external_id }}</span
            >
          </div>
          <div class="product-list-action">
            <div class="field">
              @if(sku.selected){
              <p-inputNumber
                [showButtons]="true"
                placeholder="Cantidad"
                incrementButtonClass="p-button-text p-button-sm p-button-help"
                decrementButtonClass="p-button-text p-button-sm p-button-help"
                [min]="1"
                [minFractionDigits]="isQuantity ? 1 : 0"
                [(ngModel)]="sku.amount"
                [ngModelOptions]="{ standalone: true }"
              >
              </p-inputNumber>
              }@else {
              <p-inputNumber
                [showButtons]="true"
                placeholder="Cantidad"
                incrementButtonClass="p-button-text p-button-sm p-button-help"
                decrementButtonClass="p-button-text p-button-sm p-button-help"
                [min]="1"
                [minFractionDigits]="isQuantity ? 1 : 0"
                [disabled]="true"
              >
              </p-inputNumber>
              }
            </div>
          </div>
        </div>
        } }
      </ng-template>
    </p-dataView>
  </div>

  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      pRipple
      class="p-button-text p-button-secondary"
      icon="pi pi-times"
      (click)="closeDialog()"
      label="{{ 'Cerrar' | translate }}"
    ></button>
    <button
      type="button"
      pButton
      pRipple
      class="p-button-text p-button-success"
      icon="pi pi-save"
      label="{{ 'Guardar' | translate }}"
      (click)="onSaveSelectSku()"
      [disabled]="selectedSku.length === 0"
    ></button>
  </ng-template>
</p-dialog>
