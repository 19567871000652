import { inject, Injectable } from '@angular/core';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { UserConfigsWMSListModel } from '@kanzi-apes/kanzi-models';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';
import {
  ForgotPasswordFormModel,
  LoginChangePasswordRequired,
  LoginCognitoSuccess,
  LoginFormModel,
  NewPasswordFormModel,
  UserConfigsWMSFilterModel,
} from '../../models/interfaces';
import { Observable } from 'rxjs';
import { KanziRestClientService } from '@kanzi-apes/kanzi-rest-api';

/**
 * @author Hugo Andrés Escobar Ciceri
 * @version 7.0.0
 *
 * Service with the login and authentication functions.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private httpClient = inject(KanziRestClientService);
  private envService = inject(EnvironmentService);
  private userPool: CognitoUserPool | null = null;
  private userConfigsWMSUrl = `${this.envService.wmsEnvironment?.apiRestModules.kongWMSAPI?.userConfigs.apiURL}`;
  private userConfigsWMSPath = `${this.envService.wmsEnvironment?.apiRestModules.kongWMSAPI?.userConfigs.apiPath}`;
  cognitoUser?: CognitoUser;
  poolData = {
    UserPoolId:
      this.envService.wmsEnvironment?.cognitoConfig?.cognitoUserPoolId || '',
    ClientId:
      this.envService.wmsEnvironment?.cognitoConfig?.cognitoAppClientId || '',
  };

  get cognitoUserObject(): CognitoUser | undefined {
    return this.cognitoUser;
  }

  /**
   * Method to get the current authenticated user.
   * @returns CognitoUser | null
   */
  currentAuthenticatedUser(): CognitoUser | null {
    console.log('poolData', this.poolData);
    this.userPool = new CognitoUserPool(this.poolData);
    return this.userPool.getCurrentUser();
  }

  /**
   * Method to get the current authenticated user.
   * @returns CognitoUser | null
   */
  currentAuthenticatedUserObservable(): Observable<CognitoUser | null> {
    console.log('currentAuthenticatedUserObservable', this.poolData);
    this.userPool = new CognitoUserPool(this.poolData);
    return new Observable((observer) => {
      if (this.userPool) {
        let user = this.userPool.getCurrentUser();
        if (user) {
          this.cognitoUser = user;
          observer.next(user);
          observer.complete();
        } else {
          observer.next(null);
          observer.complete();
        }
      } else {
        observer.error('UserPool not found');
      }
    });
  }

  getSessionValidUserObservable(
    userCognito: CognitoUser | null
  ): Observable<boolean> {
    console.log('getSessionValidUserObservable', userCognito);
    return new Observable((observer) => {
      if (!userCognito) {
        observer.next(false);
        observer.complete();
      }
      userCognito?.getSession((err: any, session: CognitoUserSession) => {
        if (err) {
          observer.error(err);
        } else {
          observer.next(session.isValid());
          observer.complete();
        }
      });
    });
  }

  getUserAttributesObservable(userCognito: CognitoUser): Observable<any> {
    return new Observable((observer) => {
      userCognito.getUserAttributes((err, result) => {
        if (err) {
          observer.error(err);
        } else {
          observer.next(
            result?.map((attr) => {
              return { [attr.getName()]: attr.getValue() };
            })
          );
          observer.complete();
        }
      });
    });
  }

  /**
   * Method to loging the user.
   * @param loginForm {LoginFormModel} - Login form model
   * @returns Observable<CognitoUserSession>
   */
  loginUser(
    loginForm: LoginFormModel
  ): Observable<LoginCognitoSuccess | LoginChangePasswordRequired> {
    let authenticationDetails = new AuthenticationDetails({
      Username: loginForm.username,
      Password: loginForm.password,
    });
    this.userPool = new CognitoUserPool(this.poolData);
    let userData = { Username: loginForm.username, Pool: this.userPool };
    this.cognitoUser = new CognitoUser(userData);
    return new Observable((observer) => {
      this.cognitoUser?.authenticateUser(authenticationDetails, {
        onSuccess: (userSession, userConfirmation) => {
          observer.next({
            userSession: userSession,
            userConfirmation: userConfirmation,
          });
          observer.complete();
        },
        newPasswordRequired: (userAttibutes, requiredAttributes) => {
          observer.next({
            challengeName: 'NEW_PASSWORD_REQUIRED',
            userAttibutes,
            requiredAttributes,
          });
          observer.complete();
        },
        onFailure: (err) => {
          observer.error(err);
        },
      });
    });
  }

  /**
   * Method to logout the user.
   * @returns Observable<boolean> - Observable with the logout result.
   */
  logoutUser(): Observable<boolean> {
    this.userPool = new CognitoUserPool(this.poolData);
    let cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
      return new Observable((observer) => {
        observer.next(true);
        observer.complete();
      });
    } else {
      return new Observable((observer) => {
        observer.error('No user found');
      });
    }
  }

  /**
   * Method to confirm the new password.
   * @param newPassword {NewPasswordFormModel} - New password form model
   * @returns Oberable<CognitoUserSession>
   */
  changeNewPassword(
    newPassword: NewPasswordFormModel
  ): Observable<CognitoUserSession> {
    return new Observable((observer) => {
      if (this.cognitoUser) {
        this.cognitoUser.completeNewPasswordChallenge(
          newPassword.newPassword,
          {},
          {
            onSuccess: (userSession) => {
              observer.next(userSession);
              observer.complete();
            },
            onFailure: (err) => {
              observer.error(err);
              observer.complete();
            },
          }
        );
      }
    });
  }

  /**
   * Method to restore the password.
   * @param username {string} - Username to restore the password
   * @returns Observable<any>
   */
  restorePassword(username: string): Observable<any> {
    return new Observable((observer) => {
      this.userPool = new CognitoUserPool(this.poolData);
      let userData = { Username: username, Pool: this.userPool };
      this.cognitoUser = new CognitoUser(userData);
      this.cognitoUser.forgotPassword({
        onSuccess: (data) => {
          observer.next(data);
          observer.complete();
        },
        onFailure: (err) => {
          observer.error(err);
          observer.complete();
        },
      });
    });
  }

  /**
   * Metheod to confirm the new password.
   * @param confirmPassword {ForgotPasswordFormModel} - Confirm Password Model
   * @returns Observable<any> - Observable with the result of the confirmation.
   */
  confirmNewPassword(
    confirmPassword: ForgotPasswordFormModel
  ): Observable<any> {
    return new Observable((observer) => {
      if (this.cognitoUser) {
        this.cognitoUser.confirmPassword(
          confirmPassword.code,
          confirmPassword.new_password,
          {
            onSuccess: (data) => {
              observer.next(data);
              observer.complete();
            },
            onFailure: (err) => {
              observer.error(err);
            },
          }
        );
      }
    });
  }

  obtainConfigUser(
    confiFilters: UserConfigsWMSFilterModel
  ): Observable<UserConfigsWMSListModel> {
    const url = `${this.userConfigsWMSUrl}${this.userConfigsWMSPath}`;
    return this.httpClient.get(
      url,
      confiFilters,
      'Error al listar las configuraciones',
      {},
      { tokenName: 'KanziWMSUserToken', tokenPrefix: 'COGNITO' }
    );
  }
}
