import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTH_FEATURE_KEY, AuthState, authAdapter } from './auth.reducer';

// Lookup the 'Auth' feature state managed by NgRx
export const getAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

const { selectAll, selectEntities } = authAdapter.getSelectors();

export const getAuthLoading = createSelector(
  getAuthState,
  (state: AuthState) => state.loading
);

export const getAuthError = createSelector(
  getAuthState,
  (state: AuthState) => state.error
);

export const getAllAuth = createSelector(getAuthState, (state: AuthState) =>
  selectAll(state)
);

export const getAuthEntities = createSelector(
  getAuthState,
  (state: AuthState) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getAuthState,
  (state: AuthState) => state.selectedId
);

export const getSelected = createSelector(
  getAuthEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getAuthLoggedIn = createSelector(
  getAuthState,
  (state: AuthState) => state.loggedIn
);

export const getAuthToken = createSelector(
  getAuthState,
  (state: AuthState) => state.token
);

export const getAuthUsername = createSelector(
  getAuthState,
  (state: AuthState) => state.username
);

export const getAuthUser = createSelector(
  getAuthState,
  (state: AuthState) => state.user
);

export const getAuthNewPasswordActivated = createSelector(
  getAuthState,
  (state: AuthState) => state.newPasswordActivated
);

export const getAuthUserSignInData = createSelector(
  getAuthState,
  (state: AuthState) => state.userSignInData
);

export const getAuthForgotPasswordActivated = createSelector(
  getAuthState,
  (state: AuthState) => state.forgotPasswordActivated
);

export const getAuthResetPasswordActivated = createSelector(
  getAuthState,
  (state: AuthState) => state.resetPasswordActivated
);

export const getAuthResendCode = createSelector(
  getAuthState,
  (state: AuthState) => state.resendCode
);

export const getAuthConfirmCodeActivated = createSelector(
  getAuthState,
  (state: AuthState) => state.confirmCodeActivated
);

export const getAuthUserProfile = createSelector(
  getAuthState,
  (state: AuthState) => state.userProfile
);

export const getAuthUserWMSConfig = createSelector(
  getAuthState,
  (state: AuthState) => state.userWMSConfig
);

export const getAuthUserWMSConfgFilters = createSelector(
  getAuthState,
  (state: AuthState) => state.userConfigsWMSFilter
);
