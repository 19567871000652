/* eslint-disable @angular-eslint/component-selector */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  signal,
} from '@angular/core';
import {
  Subject,
  interval,
  map,
  take,
  takeUntil
} from 'rxjs';

@Component({
  selector: 'kanzi-ui-counter',
  exportAs: 'kanziCounter',
  standalone: true,
  imports: [CommonModule],
  template: `
    <span class="countDown"
      ><i class="fa-solid fa-clock" style="margin-right: 4px;"></i
      >{{ countDown() }} Seg</span
    >
  `,
  styles: `
    .countDown{
      font:normal normal 500 16px Montserrat-SemiBold;
      color:#CF1322
    }

  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KanziUiCounterComponent implements OnDestroy {
  private wasStopped:'STOPPED' | 'COMPLETE' | 'FINALIZE' = 'COMPLETE';
  public counterControl$ = new Subject<void>();
  public countDown = signal<number>(0);

  @Output()
  public completeCountEvent = new EventEmitter<'STOPPED' | 'COMPLETE' | 'FINALIZE'>();

  public start(time: number | null) {
    this.wasStopped = 'COMPLETE';
    if (!time || time === 0) return;
    interval(1000)
      .pipe(
        map((value) => value + 1),
        take(time),
        takeUntil(this.counterControl$)
      )
      .subscribe({
        next: (value) => {
          this.countDown.set(value);
        },
        complete: () => {
            this.completeCountEvent.emit(this.wasStopped);
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  public stop() {
    this.wasStopped = 'STOPPED';
    this.completeSubject();
  }

  public finalize(){
    this.wasStopped = 'FINALIZE';
    this.completeSubject();
  }

  public completeSubject(){
    this.counterControl$.next();
    this.counterControl$.complete();
    this.counterControl$ = new Subject<void>();
    this.countDown.set(0);
  }

  public ngOnDestroy(): void {
    this.counterControl$.next();
    this.counterControl$.complete();
  }
}
