import { LocationsWMSFilterModel } from '@kanzi-apes/kanzi-models';
import { getUrlPresignedFilter } from '../models/locations-wms/locations.interface';

export const WarehouseQueryKeys = {
  all: (filters: LocationsWMSFilterModel) => ['warehouseRoot', filters],
};

export const WarehouseManagementQueryKeys = {
  all: (filters: LocationsWMSFilterModel) => ['warehouseManagment', filters],
};

export const WarehouseManagementPositionsQueryKeys = {
  all: (filters: LocationsWMSFilterModel) => [
    'warehouseManagmentPositions',
    filters,
  ],
};

export const WarehouseManagementZonesQueryKeys = {
  all: (filters: LocationsWMSFilterModel) => [
    'warehouseManagmentZones',
    filters,
  ],
};

export const LocationsTypesQueryKeys = {
  all: () => ['locations-types'],
};

export const UrlPresignedKeys = {
  all: (filters: getUrlPresignedFilter) => ['url-presigned', filters],
}