/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @nx/enforce-module-boundaries */
import {
  Component,
  effect,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';
import { Services, ServicesSelectedModel } from './services.interfaces';
import { ServicesService } from './services.service';

@Component({
  selector: 'kanzi-dynamic-table-services',
  exportAs:'tableServices',
  templateUrl: './kanzi-dynamic-table-services.component.html',
  styleUrls: ['./kanzi-dynamic-table-services.component.scss'],
})
export class KanziDynamicTableServicesComponent implements OnInit, OnChanges {
  @Input() resetServices = false;
  @Input() estimatedTime = 0;
  @Input() set loadList(data: ServicesSelectedModel[]) {
    if (data && data.length) {
      this.services.set(data);
    }
  }
  @Input() loading = false;
  @Output() onServicesSelected = new EventEmitter<ServicesSelectedModel[]>();
  services = signal<ServicesSelectedModel[]>([]);
  servicesCopy = signal<ServicesSelectedModel[]>([]);
  servicesEditMode = new Set<number>();
  servicesListOptions: Services[] = [];
  viewAddServices = false;
  formService: UntypedFormGroup = this.fb.group({
    service: [null, [Validators.required]],
    amount: [null, [Validators.required]],
    basePrice: [null],
  });

  get basePrice() {
    return this.formService.get('basePrice');
  }

  get activeEditMode(){
    return this.servicesEditMode.size>0;
  }

  constructor(
    private servicesService: ServicesService,
    private fb: UntypedFormBuilder,
    public envService: EnvironmentService
  ) {
    effect(
      () => {
        this.servicesCopy.set(this.services().map((item) => ({ ...item })));
      },
      { allowSignalWrites: true }
    );
  }

  ngOnChanges(): void {
    if (this.resetServices) {
      this.formService.reset();
      this.services.set([]);
      this.onServicesSelected.emit(this.services());
    }
  }

  ngOnInit(): void {
    this.servicesService.getServices().subscribe((services) => {
      if (services) {
        this.servicesListOptions = services.results;
      }
    });

    this.formService.get('service')?.valueChanges.subscribe((value) => {
      if (value) {
        value.price > 1 ? this.basePrice?.disable() : this.basePrice?.enable();
        this.basePrice?.patchValue(value.price);

        if (value.description === 'SERVICIO MANO DE OBRA') {
          this.formService.get('amount')?.patchValue(this.estimatedTime);
        } else {
          this.formService.get('amount')?.reset();
        }
      }
    });
  }

  addServices() {
    this.services.update((current) =>
      current.concat({
        id: this.formService.get('service')?.value.id,
        external_id: this.formService.get('service')?.value.external_id,
        description: this.formService.get('service')?.value.description,
        price: this.basePrice?.value,
        amount: this.formService.get('amount')?.value,
        total_price:
          this.basePrice?.value * this.formService.get('amount')?.value,
      })
    );
    this.viewAddServices = false;
    this.formService.reset();
    this.onServicesSelected.emit(this.services());
  }

  onRowEditSave(row: ServicesSelectedModel) {
    const findObj = this.servicesCopy().find((o) => o.id === row.id);
    if (findObj) {
      row.amount = findObj?.amount;
      row.total_price = findObj?.total_price;
    }
    this.onServicesSelected.emit(this.services());
    this.servicesEditMode.delete(row.id);
  }

  onRowDelete(row: ServicesSelectedModel) {
    this.services.update((current) => current.filter((s) => s.id !== row.id));
    this.onServicesSelected.emit(this.services());
  }

  onChangeValue(event: number, index: number) {
    this.servicesCopy.update((current) => {
      current[index].amount = event;
      current[index].total_price = current[index].price * event;

      return current;
    });
  }

  onEditInit(row: ServicesSelectedModel) {
    this.servicesEditMode.add(row.id);
  }

  onEditClose(row:ServicesSelectedModel){
    this.servicesEditMode.delete(row.id);
  }
}
