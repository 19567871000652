import { LocationModel } from '../location';
import { CustomerModel } from '../customer';
import { FiltersModel } from '../filters';
import { DataListModel, ListResults } from '../data';
import { UserData } from 'amazon-cognito-identity-js';

export interface Authenticate {
  username: string;
  password: string;
}

export interface StatusLogin {
  status: boolean;
}

export interface Token {
  auth_token: string;
}

export interface UserModel {
  id: number;
  last_login?: Date;
  last_login_timestamp?: number;
  username: string;
  first_name: string;
  last_name: string;
  email?: string;
  is_active?: boolean;
  configs?: Configs;
  is_principal?: boolean;
  profile_image?: string;
  configs_role_name?: string;
}
export interface UserCreate {
  id?: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  password?: string;
  configs?: Configs | null;
}

export interface Configs {
  id: number;
  default_location: LocationModel;
  audit_locations: LocationModel[];
  active_mobile_modules: ModuleAction[];
  active_web_modules: ModuleAction[];
  customer: CustomerModel;
  role: UserRole;
  properties?: any;
}

export interface ModuleAction {
  id: number;
  code: string;
  name: string;
  description: string;
}

export interface UserFilterModel extends FiltersModel {
  page?: number;
  page_size?: number;
  location_id?: number;
  location_type?: string;
  role_id?: number;
  role__name?: string;
  role__name_list?: string;
  search?: string;
}

export interface UserRole {
  id: number;
  external_id?: string;
  name: string;
}

// Config user

export interface SetConfigsModel {
  id?: number;
  last_login?: string;
  username?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  is_active?: boolean;
  groups?: string[];
  configs?: ConfigsCreateModel;
}

export interface ConfigsCreateModel {
  id?: number;
  default_location: TLocation;
  audit_locations: TLocation[];
  active_mobile_modules: ActiveModule[];
  active_web_modules: ActiveModule[];
  customer: CustomerConfigsModel;
  role: RoleCreateModel;
}

export interface ActiveModuleList extends DataListModel {
  results: ActiveModule[];
}

export interface ActiveModule {
  id: number;
  code: string;
  name: string;
  description: null | string;
}

export interface TLocation {
  external_id: string;
  display_name: string;
  id: number;
  name: string;
  type: RoleCreateModel;
  parent_id: number;
}

export interface RoleCreateModel {
  id: number;
  external_id: string;
  name: string;
}

export interface CustomerConfigsModel {
  id: number;
  name: string;
  type_customer: string;
  identification: null;
  parent: number;
}

export interface RolesListModel {
  count: number;
  next: null;
  previous: null;
  results: RolesModel[];
}

export interface RolesModel {
  id: number;
  created: string;
  modified: string;
  external_id: string;
  name: string;
  created_by: number;
  modified_by: number;
}

export interface UserCognitoModel extends UserData {}

interface DefaultWorkingHour {
  ending_hour: string;
  days: string[];
  starting_hour: string;
}

interface MobileModule {
  name: string;
  order_type_ids: string[];
}

interface WMSWebModule {
  permitions: string[];
  module: string;
}
interface UserWMSRole {
  status: string;
  name: string;
  mobile_modules: WMSWebModule[];
  external_id: string;
  description: string;
  id: string;
  web_modules: any[];
  modified: string;
}
export interface UserConfigsWMSModel {
  complete_tree_location_ids: string[];
  default_working_hours: DefaultWorkingHour[];
  can_drive_forklift: boolean;
  default_location_id: string;
  status: string;
  created: string;
  blood_type: string;
  partial_tree_location_ids: string[];
  emergency_contact: any;
  modified_unix_epoch: number;
  warehouse_ids: string[];
  can_work_at_heights: boolean;
  role_ids: string[];
  mobile_modules: MobileModule[];
  created_unix_epoch: number;
  always_available_for_assignment: boolean;
  external_id: string;
  occupational_risk_entity: string;
  health_entity: string;
  created_by_username: string;
  username: string;
  id: string;
  modified_by_username: string;
  modified: string;
  location: any;
  customers: any;
  roles: UserWMSRole[];
  warehouses: any[];
  web_modules: WMSWebModule[];
}

export type UserConfigsWMSListModel = ListResults<UserConfigsWMSModel>;

export interface IUrlPresignedResponse {
  result_data: ResultData;
  status_code: number;
  message:     string;
}

export type IUrlPresignedResponseList = IUrlPresignedResponse
export interface ResultData {
  presigned_url: string;
  field:         string;
  file_type:     string;
  username:      string;
  user:          UserWMSModel;
}
export interface UserWMSModel {
  username: string;
  enabled: boolean;
  status: string;
  name: string;
  rol_name: string;
  phone_number: string;
  phone_number_verified: boolean;
  email: string;
  email_verified: boolean;
  picture: string;
  profile: string;
}

export type UserWMSListModel = ListResults<UserWMSModel>;

export interface UserWMSFilterModel {
  last_evaluated_key_id?: string | null | undefined;
  username__eq?: string | null | undefined;
  field?: string | null | undefined;
  username?: string | null | undefined;
  file_type?: string | null | undefined;
}


export interface IUserRole {
  status:               string;
  created:              Date;
  name:                 string;
  modified_unix_epoch:  number;
  mobile_modules:       string[];
  created_unix_epoch:   number;
  external_id:          string;
  created_by_username:  string;
  description:          string;
  id:                   string;
  modified_by_username: string;
  web_modules:          WebModule[];
  modified:             Date;
}

export interface WebModule {
  permitions: string[];
  module:     string;
}


export type UserRolesListModel = ListResults<IUserRole>;