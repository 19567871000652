import {
  TokenModel,
  UserCognitoModel,
  UserConfigsWMSFilterModel,
  UsersModel,
} from '../models/interfaces';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as AuthActions from './auth.actions';
import { AuthEntity } from './auth.models';
import * as lcStorage from '@kanzi-apes/kanzi-utils';
import { UserConfigsWMSModel } from '@kanzi-apes/kanzi-models';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState extends EntityState<AuthEntity> {
  selectedId?: string | number; // which Auth record has been selected
  loading: boolean;
  loggedIn: boolean;
  token: TokenModel | null;
  user: UserCognitoModel | null;
  username: string | null;
  newPasswordActivated: boolean;
  forgotPasswordActivated: boolean;
  resetPasswordActivated: boolean;
  confirmCodeActivated: boolean;
  resendCode: boolean;
  userSignInData: any | null;
  userProfile: UsersModel | null;
  userWMSConfig: UserConfigsWMSModel | null;
  userConfigsWMSFilter: UserConfigsWMSFilterModel | null;
  error: any | null;
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthState;
}

export const authAdapter: EntityAdapter<AuthEntity> =
  createEntityAdapter<AuthEntity>();

export const initialAuthState: AuthState = authAdapter.getInitialState(
  (lcStorage.loadState() && lcStorage.loadState()['auth']) || {
    loading: false,
    loggedIn: false,
    token: null,
    user: null,
    username: null,
    newPasswordActivated: false,
    forgotPasswordActivated: false,
    resetPasswordActivated: false,
    confirmCodeActivated: false,
    resendCode: false,
    userSignInData: null,
    userProfile: null,
    userWMSConfig: null,
    error: null,
  }
);

const reducer = createReducer(
  initialAuthState,
  on(AuthActions.initAuth, (state) => ({
    ...state,
    loading: false,
    loggedIn: false,
    token: null,
    user: null,
    newPasswordActivated: false,
    forgotPasswordActivated: false,
    resetPasswordActivated: false,
    confirmCodeActivated: false,
    resendCode: false,
    username: null,
    userSignInData: null,
    userWMSConfig: null,
    error: null,
  })),
  on(AuthActions.login, (state, { loginForm }) => ({
    ...state,
    username: loginForm.username,
  })),
  on(AuthActions.loginSuccess, (state) => ({
    ...state,
    loggedIn: true,
  })),
  on(AuthActions.logout, (state) => ({
    ...state,
    loggedIn: false,
    token: null,
    user: null,
    newPasswordActivated: false,
    resetPasswordActivated: false,
    forgotPasswordActivated: false,
    resendCode: false,
    userSignInData: null,
    userProfile: null,
    userWMSConfig: null,
  })),
  on(AuthActions.userProfileLoad, (state) => ({
    ...state,
  })),
  on(AuthActions.userProfileSuccess, (state, { user }) => {
    Object.freeze(user);
    return { ...state, user: user };
  }),
  on(AuthActions.completeNewPasswordActivated, (state, { userSignInData }) => {
    Object.freeze(userSignInData);
    return {
      ...state,
      newPasswordActivated: true,
      resetPasswordActivated: false,
      forgotPasswordActivated: false,
      resendCode: false,
      userSignInData: userSignInData,
    };
  }),
  on(AuthActions.completeNewPassword, (state) => ({
    ...state,
    loading: true,
  })),
  on(AuthActions.CompleteNewPasswordSuccess, (state) => ({
    ...state,
    loading: false,
    newPasswordActivated: false,
    resetPasswordActivated: false,
    forgotPasswordActivated: false,
    resendCode: false,
  })),
  on(AuthActions.completeNewPasswordFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  })),
  on(AuthActions.forgotPasswordActivated, (state) => {
    return {
      ...state,
      forgotPasswordActivated: true,
      newPasswordActivated: false,
      resetPasswordActivated: false,
      resendCode: false,
    };
  }),
  on(AuthActions.forgotPassword, (state) => ({
    ...state,
    loading: true,
  })),
  on(AuthActions.forgotPasswordSuccess, (state) => ({
    ...state,
    loading: false,
    forgotPasswordActivated: false,
  })),
  on(AuthActions.forgotPasswordFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  })),
  on(AuthActions.resetPasswordActivated, (state, { resendCode }) => {
    return {
      ...state,
      resetPasswordActivated: true,
      forgotPasswordActivated: false,
      newPasswordActivated: false,
      resendCode: resendCode,
    };
  }),
  on(AuthActions.resetPassword, (state, { resetLoginForm }) => ({
    ...state,
    loading: true,
    username: resetLoginForm.username,
  })),
  on(AuthActions.resetPasswordSuccess, (state) => ({
    ...state,
    loading: false,
    resetPasswordActivated: false,
    resendCode: false,
  })),
  on(AuthActions.resetPasswordFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  })),
  on(AuthActions.resendCode, (state, { resetLoginForm }) => ({
    ...state,
    loading: true,
    username: resetLoginForm.username,
  })),
  on(AuthActions.resendCodeSuccess, (state) => ({
    ...state,
    loading: false,
    resetPasswordActivated: false,
    resendCode: false,
  })),
  on(AuthActions.resendCodeFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  })),
  on(AuthActions.confirmCodeActivated, (state) => {
    return {
      ...state,
      forgotPasswordActivated: false,
      newPasswordActivated: false,
      resetPasswordActivated: false,
      resendCode: false,
      confirmCodeActivated: true,
    };
  }),
  on(AuthActions.confirmCode, (state) => ({
    ...state,
    loading: true,
  })),
  on(AuthActions.confirmCodeSuccess, (state, { code }) => ({
    ...state,
    loading: false,
    confirmCodeActivated: false,
  })),
  on(AuthActions.confirmCodeFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  })),
  on(AuthActions.loadUserSuccess, (state, { user }) => ({
    ...state,
    userProfile: user,
  })),
  on(AuthActions.loadUserFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
  on(AuthActions.loginFormActivated, (state) => {
    return {
      ...state,
      forgotPasswordActivated: false,
      newPasswordActivated: false,
      resetPasswordActivated: false,
      resendCode: false,
    };
  }),
  on(AuthActions.loadUserConfigsWMS, (state, { userConfigFilters }) => ({
    ...state,
    userConfigsWMSFilter: userConfigFilters,
  })),
  on(AuthActions.loadUserConfigsWMSSuccess, (state, { userConfig }) => ({
    ...state,
    userWMSConfig: userConfig.results[0],
  })),
  on(AuthActions.loadUserConfigsWMSFailure, (state, { error }) => ({
    ...state,
    error: error,
  }))
);

export function authReducer(state: AuthState | undefined, action: Action) {
  return reducer(state, action);
}
