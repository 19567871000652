export class FiltersFunction {
  constructor() {}

  filterText<T>(query: string, list: T[], param: string): T[] {
    const filtered: any[] = [];
    list.forEach((elem: any) => {
      if (elem[param].toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(elem);
      }
    });
    return filtered.length > 0 ? filtered : list;
  }
}


export const currencyFormatter = new Intl.NumberFormat('es-CO', {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 1,
});
